import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik, FormikErrors } from "formik";
import { TextField } from "../../../components/TextField/TextField";
import { loginAdmin } from "../../../apis/userApi";
import { AdminHeader } from "../../../components/Admin/Header/AdminHeader";
import { STATUS_SUCCESS_200 } from "constants/constants";
import { eyeBlocked, eye } from "react-icons-kit/icomoon";
import Icon from "react-icons-kit";

const AdminLoginSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
});

export const AdminLogin = React.memo(() => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = useCallback(
    async (
      values: { password: string },
      setErrors: (
        errors: FormikErrors<{
          password: string;
        }>
      ) => void
    ) => {
      const response = await loginAdmin(values);
      if (response?.status === STATUS_SUCCESS_200) {
        sessionStorage.setItem("isAdmin", "true");
        navigate("/admin/customersInfo");
      } else {
        setErrors({ password: "Invalid Password" });
      }
    },
    [navigate]
  );

  return (
    <div>
      <AdminHeader />
      <div className="container col-sm-12 p-4 ">
        <div className="d-flex justify-content-center">
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={AdminLoginSchema}
            onSubmit={async (values: { password: string }, { setErrors }) => {
              handleLogin(values, setErrors);
            }}>
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div className="p-3 col-sm-4 card ">
                <h3>Login</h3>
                <Form onSubmit={handleSubmit}>
                  <div className="col-sm-10 pt-3 ">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      label="Password"
                      value={values.password}
                      onChange={handleChange}
                      component={TextField}
                      onBlur={handleBlur}
                    />
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      icon={showPassword ? eye : eyeBlocked}></Icon>
                  </div>
                  <div className="pt-3">
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={isSubmitting}>
                      Login
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
});
