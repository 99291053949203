import React, { useCallback } from "react";
import { postWidgetAnswer } from "../../apis/questionnaireResponseApi";
import {
  FINANCIAL_KNOWLEDGE,
  INVESTMENT_PORTFOLIO_THEME_WIDGET_ID,
  VOTE_NO,
  VOTE_YES,
  YES,
} from "../../constants/constants";
import { useSessionStorage } from "../../store/hooks/useSessionStorage";

type ThemeWidgetProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  knowledge: string;
};

export const ThemeWidget = React.memo<ThemeWidgetProps>(({ setModal }) => {
  const [financialKnowledge] = useSessionStorage<string>(FINANCIAL_KNOWLEDGE);

  const handleAnswer = useCallback((answer: string) => {
    answerHandler(answer);
  }, []);

  const answerHandler = useCallback(async (userResponse: string) => {
    setModal((modal: boolean) => !modal);
    await postWidgetAnswer(INVESTMENT_PORTFOLIO_THEME_WIDGET_ID, userResponse);
  }, []);

  return (
    <>
      <div className="widget_popup_section">
        <div className="row ">
          <div className="widget_logo">
            <img src="./images/logo.png" alt="" />
          </div>
          <button
            type="button"
            className="btn-close flex-reverse"
            onClick={() => {
              setModal(false);
            }}
            aria-label="Close"></button>
        </div>
        <ul>
          {financialKnowledge === YES ? (
            <>
              <li>
                Wealthzen translates macroeconomic data into signals like growth
                and inflation
              </li>
              <li>
                These macro signals help us construct the optimal asset
                allocation
              </li>
              <li>
                We identify relevant investment themes to include in your
                portfolio
              </li>
            </>
          ) : (
            <>
              <li>
                Wealthzen uses data to identify market environment and expected
                direction
              </li>
              <li>
                We then allocate the money across high risk assets like stocks
                or low risk like bonds, cash
              </li>
              <li>
                We help you identify relevant thematic investment ideas to add
                to your portfolio
              </li>
            </>
          )}
        </ul>
        <div className="like_btn_block">
          <p>Was this helpful? Please answer to close the pop up!</p>
          <button
            data-bs-dismiss="modal"
            type="button"
            className="btn widget_btn"
            onClick={() => handleAnswer(VOTE_YES)}>
            Yes <img src="./images/icons/up-thumb.svg" alt="thumb_up" />
          </button>
          <button
            data-bs-dismiss="modal"
            type="button"
            className="btn widget_btn"
            onClick={() => handleAnswer(VOTE_NO)}>
            No <img src="./images/icons/down-thumb.svg" alt="thumb_down" />
          </button>
        </div>
        <div className="mt-3 popup_bottom_msg">
          <p>Your responses enable us to improve your experience</p>
        </div>
      </div>
    </>
  );
});
