import { AppState } from "../../typings/types";
import { useAppContext } from "../context";

export const useUser = () => {
  const [state, setState] = useAppContext();

  const getUser = () => {
    return state.user;
  };

  const setUser = (userData: { name: string } | null) => {
    setState((prev: AppState) => {
      const newStates = { ...prev, user: userData };
      sessionStorage.setItem("appState", JSON.stringify(newStates));
      return newStates;
    });
  };

  const setCustomerId = (customerId: string) => {
    setState((prev: AppState) => {
      const newStates = { ...prev, customerId: customerId };
      sessionStorage.setItem("appState", JSON.stringify(newStates));
      return newStates;
    });
  };

  const getCustomerId = () => {
    return state.customerId;
  };

  return {
    currentUser: state.user,
    getUser,
    setUser,
    setCustomerId,
    getCustomerId,
  };
};
