import { Routes, Route } from "react-router-dom";
import { AdminLogin } from "pages/AdminPage/AdminLogin/AdminLogin";
import { AdminPortal } from "pages/AdminPage/AdminPortal/AdminPortal";
import { PortfolioUpload } from "pages/PortfolioPage/PortfolioUpload/PortfolioUpload";
import { PortfolioDetails } from "../pages/PortfolioPage/PortfolioDetails/PortfolioDetails";
import RequireAdminAuth from "components/RequireAuth/RequireAdminAuth";
// import { shutdown as shutdownIntercom } from "../intercom";
import { useEffect } from "react";
import ShowVersions from "components/Admin/ShowVersions/ShowVersions";

const AdminRoutes = () => {
  // useEffect(() => {
  //   shutdownIntercom();
  // }, []);
  return (
    <Routes>
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route element={<RequireAdminAuth />}>
        <Route path="/admin/customersInfo" element={<AdminPortal />} />
        <Route path="/admin/portfolio/upload" element={<PortfolioUpload />} />
        <Route
          path="admin/customersInfo/portfolio/details/:id"
          element={<PortfolioDetails />}
        />
        <Route path="/admin/versionControl" element={<ShowVersions />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
