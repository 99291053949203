import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import { getAllCustomerData } from "../../../apis/adminPortalApi";
import { CustomerPortfolio } from "typings/PortfolioPage/types/types";
import { Table } from "components/Table/Table";
import { portfolioTableHeaders } from "./PortfolioTableHeaders";
import { FUNDED, REBALANCED, STATUS_SUCCESS_200 } from "constants/constants";

export const PortfolioTable = React.memo(() => {
  const [customersData, setCustomersData] = useState<CustomerPortfolio[]>([]);
  const [failure, setFailure] = useState<string>("");

  const getCustomerData = useCallback(async () => {
    const allCustomersPortfolio = await getAllCustomerData();
    if (allCustomersPortfolio?.status === STATUS_SUCCESS_200) {
      setCustomersData(allCustomersPortfolio.data);
    } else {
      setFailure(allCustomersPortfolio.error);
    }
  }, []);

  const returnCurrentValue = (data: CustomerPortfolio) => {
    if (data.status === FUNDED) {
      return 10000;
    } else {
      return data.aggregatedPortfolioStatsByAsOfDate[0][0].currentValue;
    }
  };

  const customerTableData = useMemo(() => {
    const tableData: Array<{
      [key: string]: string | number | undefined;
    }> = [];

    customersData?.forEach((cusPortfolio) => {
      tableData.push({
        name: cusPortfolio.userName,
        email: cusPortfolio.email,
        user_Id: cusPortfolio.userId,
        portfolio_Id: cusPortfolio.portfolioId,
        portfolio_Number: cusPortfolio.portfolioName,
        customer_Portfolio_Id: cusPortfolio.customerPortfolioId,
        status: cusPortfolio.status,
        current_Value: returnCurrentValue(cusPortfolio),
        details: cusPortfolio.details,
        funded_Date: cusPortfolio.fundedDate,
        active_Version: cusPortfolio.currentVersion,
      });
    });
    return tableData;
  }, [customersData]);

  useEffect(() => {
    getCustomerData();
  }, [getCustomerData]);

  const handleOnExport = useCallback(() => {
    const date = new Date();
    const excelData = customerTableData;
    excelData.forEach(function (obj) {
      delete obj.details;
    });
    const workBook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workBook, ws, "Sheet 1");
    XLSX.writeFile(
      workBook,
      "customer_portfolios_" +
        date.getDate() +
        "-" +
        date.getMonth() +
        "-" +
        date.getFullYear() +
        "_" +
        date.getHours() +
        "-" +
        date.getMinutes() +
        ".xlsx"
    );
  }, [customerTableData]);

  return (
    <div className="card shadow m-4">
      {customerTableData?.length ? (
        <div>
          <div className="d-flex flex-row-reverse">
            <b className="m-3">Count: {customersData.length}</b>
          </div>
          <div className="d-flex flex-row-reverse m-3 ">
            <button className="btn btn-success m-2" onClick={handleOnExport}>
              Export to Excel
            </button>
          </div>
          <div className="d-flex overflow-auto m-3 ">
            <table className="table table-bordered holding_table">
              <Table
                columns={portfolioTableHeaders}
                tableData={customerTableData}
              />
            </table>
          </div>
        </div>
      ) : null}
      {failure && (
        <div className="m-4 text-center row text-danger">
          <h5> {failure}</h5>
        </div>
      )}
    </div>
  );
});
