import React, { useCallback, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { generateOtp } from "apis/userApi";
import { RegistrationHeader } from "components/Registration/Header/RegistrationHeader";
import { TextField } from "components/TextField/TextField";
import SocialAuth from "components/SocialAuth/SocialAuth";
import {
  EMAIL,
  STATUS_SUCCESS_200,
  STATUS_SUCCESS_201,
} from "constants/constants";
import { useSessionStorage } from "store/hooks/useSessionStorage";

const UserLoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export const UserLogin = React.memo(() => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [, setEmail] = useSessionStorage<string>(EMAIL);

  const handleUserLogin = useCallback(
    async (values: { email: string }) => {
      setEmail(values.email);
      const response = await generateOtp({ email: values.email });
      if (
        response.status === STATUS_SUCCESS_200 ||
        response.status === STATUS_SUCCESS_201
      ) {
        navigate("/verification/otp");
      } else {
        setErrorMessage(response.description);
      }
    },
    [navigate, setEmail]
  );

  return (
    <>
      <RegistrationHeader>
        <div className="sign_right_block">
          <div className="sign_right_head">
            <h1>Login</h1>
            <p>Sign in to view your dashboard</p>
          </div>
          <div className="card sign_right_card mt28">
            <SocialAuth />
            <div className="sign_right_form">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={UserLoginSchema}
                onSubmit={async (values: { email: string }, { setErrors }) => {
                  handleUserLogin(values);
                }}>
                {({
                  values,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="input_form">
                      <Field
                        type="email"
                        id="emailAddress"
                        name="email"
                        label="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        component={TextField}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errorMessage && (
                      <p className="error text-capitalize">{errorMessage}</p>
                    )}
                    <div className="form_btn">
                      <button className="btn primary_btn" type="submit">
                        Continue
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <p className="already_acc">
                Don’t have an account? Click&nbsp;
                <a href="/profiling">here</a>&nbsp; to explore WealthZen
              </p>
            </div>
          </div>
        </div>
      </RegistrationHeader>
    </>
  );
});
