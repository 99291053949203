import { BrowserRouter as Router, useLocation } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import AdminRoutes from "./AdminRoutes";
import { createBrowserHistory } from "history";
import {
  load as loadIntercom,
  // boot as bootIntercom,
  // update as updateIntercom,
} from "../intercom";
import { useEffect } from "react";
const history = createBrowserHistory();
// history.listen((location) => {
//   // Calls Intercom('update') on every page change
//   updateIntercom();
// });

const RoutesPath = () => {
  const path = history.location.pathname.split("/")[1];
  // loadIntercom();
  // useEffect(() => {
  //   bootIntercom({
  //     email: sessionStorage.getItem("email"),
  //   });
  // });
  return (
    <Router>{path !== "admin" ? <PublicRoutes /> : <AdminRoutes />}</Router>
  );
};

export default RoutesPath;
