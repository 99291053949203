import { getUsername } from "apis/userApi";
import React, { useCallback, useEffect, useState } from "react";
import { useQuestions } from "store/hooks/useQuestion";
import { useAppContext } from "../../../store/context";
import { useUser } from "../../../store/hooks/useUser";
import {
  QUESTION_ID_1001,
  QUESTION_ID_2001,
  SECTION_3,
  SECTION_4,
  TOKEN,
} from "constants/constants";
import { WZ_HOME_URL } from "constants/env-constants";

export const HomeHeader = React.memo(() => {
  const token = sessionStorage.getItem(TOKEN);

  const { setUser } = useUser();

  const { questions, setQuestion } = useQuestions();

  const { currentUser } = useUser();

  const [show, setShow] = useState(false);

  const [state] = useAppContext();

  const { start, headerTxt } = state;

  const { meta } = questions;

  const checkLogin = useCallback(async () => {
    if (token) {
      const user = await getUsername(token);
      setUser({ name: user });
    } else {
      setUser(null);
    }
  }, [setUser, token]);

  useEffect(() => {
    checkLogin();
  }, []);

  const handleDropdown = useCallback(() => {
    setShow((prev) => !prev);
  }, []);

  const handleLogout = useCallback(() => {
    sessionStorage.clear();
    // window.location.replace(WZ_HOME_URL);
  }, []);

  const handleBack = useCallback(() => {
    setQuestion(meta.currentId - 1, meta.currentSection);
  }, [meta.currentId, meta.currentSection, setQuestion]);

  return (
    <nav className="navbar navbar-expand-lg quiz_head ">
      <div className="container">
        {window.location.pathname === "/dashboard" ? (
          ""
        ) : (
          <div className="back_btn">
            {start &&
            meta.currentId !== QUESTION_ID_1001 &&
            meta.currentId !== QUESTION_ID_2001 &&
            meta.currentSection !== SECTION_3 &&
            meta.currentSection !== SECTION_4 ? (
              <div onClick={handleBack} style={{ cursor: "pointer" }}>
                <img src="/images/icons/arrow-left.png" alt="left arrow" /> Back
              </div>
            ) : null}
          </div>
        )}

        <div className="header_logo">
          <a>
            <img src="/images/logo.png" alt="logo" />
          </a>
        </div>
        <div className="already_usr">
          {currentUser === null ? (
            headerTxt && (
              <p className="">
                {/* <span>Already a WealthZen User? </span>
                <a href="/login">Login here</a> */}
              </p>
            )
          ) : (
            <div>
              <button
                className="btn sign_acc_head dropdown-toggle"
                data-bs-toggle="dropdown"
                onClick={handleDropdown}
                aria-expanded="false">
                <div className="avatar_img">
                  <p className="text-capitalize">{`${currentUser?.name?.slice(
                    0,
                    1
                  )}`}</p>
                </div>
                <p className="text-capitalize">{`${currentUser?.name}`}</p>
              </button>

              {show && (
                <ul className={`dropdown-menu show`}>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={handleLogout}>
                      Logout
                    </button>
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
});
