import { INVESTED } from "constants/constants";
import { Link } from "react-router-dom";
import { Row } from "typings/AdminPortal/types";
import { TableColumns } from "typings/Table/types";

const statusFormatter = (cell: string, row: Row) => {
  return (
    <span>
      <strong style={{ textTransform: "capitalize" }}> {cell} </strong>
    </span>
  );
};

const detailsFormatter = (cell: string, row: Row) => {
  if (row.status === INVESTED) {
    return (
      <Link
        to={`portfolio/details/${row.customer_Portfolio_Id}`}
        target="empty">
        View
      </Link>
    );
  }
  return null;
};

export const portfolioTableHeaders: TableColumns[] = [
  {
    dataField: "name",
    text: "Name",
    formatter: statusFormatter,
  },
  {
    dataField: "email",
    text: "Email ID",
  },

  {
    dataField: "user_Id",
    text: "User ID",
  },
  {
    dataField: "portfolio_Id",
    text: "Model Portfolio ID",
  },
  {
    dataField: "active_Version",
    text: "Active Version",
  },
  {
    dataField: "portfolio_Number",
    text: "Portfolio Number",
  },
  {
    dataField: "customer_Portfolio_Id",
    text: "Customer Portfolio ID",
    sort: true,
  },
  {
    dataField: "funded_Date",
    text: "Funded Date",
  },
  {
    dataField: "status",
    text: "Status",
    formatter: statusFormatter,
  },
  {
    dataField: "current_Value",
    text: "Current Value",
  },
  {
    dataField: "details",
    text: "Details",
    formatter: detailsFormatter,
  },
];
