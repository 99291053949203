import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resetUserData } from "apis/userApi";
import { useQuestions } from "store/hooks/useQuestion";
import { STATUS_SUCCESS_200 } from "constants/constants";

export const Reset = React.memo(() => {
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  const { resetQuestion } = useQuestions();

  const handleYes = async () => {
    const response = await resetUserData();
    if (response.status === STATUS_SUCCESS_200) {
      sessionStorage.setItem("reset", JSON.stringify(true));
      sessionStorage.removeItem("appState");
      sessionStorage.removeItem("financialKnowledge");
      resetQuestion();
      navigate("/profiling");
    } else {
      console.error(response);
    }
  };

  const handleModel = () => {
    setShow(!show);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="rest_block">
            <img src="/images/rest-img.png" alt="rest" />
            <h4>
              Would you like to reset your WealthZen journey and start again?
              (This will delete all your current data and risk profiles). This
              action cannot be undone
            </h4>
            <button
              className="btn primary_btn"
              type="button"
              onClick={handleModel}>
              Reset
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleModel();
        }}>
        <Modal.Header closeButton>WealthZen</Modal.Header>
        <Modal.Body>Are you sure you want to reset your portfolio?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModel}>
            No
          </Button>
          <Button variant="primary" onClick={handleYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});
