import React, { useCallback, useEffect, useState } from "react";
import { TableColumns } from "typings/Table/types";
import { ModelPortfolioConstituents } from "../typings/types";
import {
  CASH,
  COMMODITIES,
  EQUITY_DM,
  EQUITY_EM,
  EQUITY_THEME,
  FIXED_INCOME_CORP,
  FIXED_INCOME_GOVT,
  FIXED_INCOME_THEME,
  REIT,
} from "../constants/constants";

export type PortfolioMap = {
  [key: string]: ModelPortfolioConstituents[];
};

type PortfolioConstituentsProps = {
  data?: ModelPortfolioConstituents[];
  holdings: any;
};

export const PortfolioConstituents: React.FC<PortfolioConstituentsProps> = ({
  data,
  holdings,
}) => {
  const constituentsOrder = [
    EQUITY_DM,
    EQUITY_EM,
    EQUITY_THEME,
    FIXED_INCOME_GOVT,
    FIXED_INCOME_CORP,
    FIXED_INCOME_THEME,
    COMMODITIES,
    CASH,
  ];

  holdings.forEach((element: { assetclass: string }) => {
    if (constituentsOrder.indexOf(element.assetclass) === -1) {
      constituentsOrder.push(element.assetclass);
    }
  });

  const percentageFormatter = (cell: string, row: any) => {
    if (row.weight) {
      return <>{row.weight.toFixed(2)} %</>;
    } else {
      return <p>0 %</p>;
    }
  };

  const tableColumnsForETF: TableColumns[] = [
    {
      dataField: "etf",
      text: "ETF",
    },
    {
      dataField: "factor.asset_class",
      text: "Asset Class",
    },

    {
      dataField: "weight",
      text: "Suggested Allocation",
      formatter: percentageFormatter,
    },
  ];

  // useEffect(() => {
  //   sessionStorage.setItem("etfReport", JSON.stringify(holdings));
  //   sessionStorage.setItem("etfTable", JSON.stringify(tableColumnsForETF));
  // }, []);
  return (
    <div className="portfolio_consti_section p-3">
      {/* <div className="primary_head mb-5">
        <h4>Portfolio Constituents</h4>
        <p>Regime Start Date: {holdings[0].regime_start_date}</p>
      </div> */}

      <div className="m-3  col-sm-12 col-lg-8 col-xl-6   ">
        <table className="table ">
          <thead>
            <tr className="title_color">
              {tableColumnsForETF.map((col, index) => (
                <th key={index} scope="col">
                  {col.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {holdings.map((row: any, rowIndex: number) => {
              const originalWeight = parseFloat(row.weight.toFixed(2));

              return (
                <tr key={rowIndex}>
                  <td>{row.etf}</td>
                  <td>{row["factor.asset_class"]}</td>
                  <td>{originalWeight} %</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
