import { Dashboard } from "typings/HomePage/types/types";

const initialDashboardData: Dashboard = {
  customerPortfolioId: 22,
  userId: 9,
  userName: "Vinoth E",
  portfolioId: "0514d445-2516-4bc3-8dda-364c73b83661",
  status: "Invested",
  latestDate: "2022-07-04",
  customerSecurityHoldingsByAsOfDate: {
    "2022-07-04": [
      {
        id: 50,
        isin: "",
        securityName: "",
        currentHoldingPercentage: 0,
        avgPurchasePrice: 0,
        asOfDate: "2022-07-04",
        units: 0,
        investedValue: 0,
        currentValue: 0,
        ugl: 0,
        createdAt: "2022-07-04T16:28:20.401+00:00",
        updatedAt: "2022-07-04T16:28:20.401+00:00",
      },
    ],
  },
  benchMarkByAsOfDate: {
    "2022-07-04": [
      {
        id: 27,
        benchmarkIdentifier: "BTC",
        benchmarkName: "Bitcoin",
        asOfDate: "2022-07-04",
        benchmarkCurrentValue: 0,
        benchmarkInvestedValue: 0,
        createdAt: "2022-07-04T16:28:22.951+00:00",
        updatedAt: "2022-07-04T16:28:22.951+00:00",
      },
    ],
  },
  aggregatedPortfolioStatsByAsOfDate: {
    "2022-07-04": [
      {
        id: 24,
        currentValue: 0,
        investedValue: 0,
        xirr: 0,
        asOfDate: "2022-07-04",
        createdAt: "2022-07-04T16:28:18.967+00:00",
        updatedAt: "2022-07-04T16:28:18.968+00:00",
      },
    ],
  },
};

export default initialDashboardData;
