import React from "react";
import { AdminHeader } from "../../../components/Admin/Header/AdminHeader";
import { PortfolioTable } from "pages/PortfolioPage/PortfolioTable/PortfolioTable";

export const AdminPortal = React.memo(() => {
  return (
    <>
      <AdminHeader menu={true} isLogout={true} />
      <PortfolioTable />
    </>
  );
});
