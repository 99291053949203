import { separator } from "pages/HomePage/Home";

const priceFormatter = (cell: string) => {
  return <span>$ {separator(parseFloat(cell).toFixed(2))}</span>;
};
const unitFormatter = (cell: string) => {
  return <span> {parseFloat(cell).toFixed(2)}</span>;
};

export const holdingsTableHeaders = [
  {
    dataField: "securityName",
    text: "Security",
  },
  {
    dataField: "isin",
    text: "Ticker",
  },
  {
    dataField: "currentHoldingPercentage",
    text: "Holding %",
  },
  {
    dataField: "units",
    text: "Units",
    formatter: unitFormatter,
  },
  {
    dataField: "avgPurchasePrice",
    text: "Average purchase price",
    formatter: priceFormatter,
  },
  {
    dataField: "currentValue",
    text: "Current value",
    formatter: priceFormatter,
  },
  {
    dataField: "ugl",
    text: "G/L",
    formatter: priceFormatter,
  },
];
