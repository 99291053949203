import {
  EQUITY_DM,
  EQUITY_EM,
  EQUITY_THEME,
  FIXED_INCOME_GOVT,
  FIXED_INCOME_CORP,
  FIXED_INCOME_THEME,
  COMMODITIES,
  CASH,
} from "constants/constants";
import { TableColumns } from "typings/Table/types";

const constituentsOrder = [
  EQUITY_DM,
  EQUITY_EM,
  EQUITY_THEME,
  FIXED_INCOME_GOVT,
  FIXED_INCOME_CORP,
  FIXED_INCOME_THEME,
  COMMODITIES,
  CASH,
];
export const removeNegativeValues = (data: any) => {
  let objectFun: string[] = [];

  const filteredData = data.optimisation_result
    ?.filter((item: { weight: number }) => {
      return !item.weight.toString().includes("e-") && item.weight > 0.0;
    })
    .filter((item: { objective_function: any; weight: number }) => {
      const temp = item.objective_function;

      if (objectFun.indexOf(temp) === -1) {
        objectFun.push(temp);
      }
      item.weight *= 100;
      return true;
    });
  return [objectFun, filteredData];
};

export const calculateSum = (filteredArr: any[], objectiveFunction: string) => {
  const sum = 0;
  const filteredArrWithoutObjective = filteredArr.filter(
    (item: { objective_function: string | any[] }) =>
      item.objective_function === objectiveFunction
  );

  return filteredArrWithoutObjective;
};

export const combineWeightsByAllAssetClass = (
  records: ETFRecord[]
): ETFRecord[] => {
  const combinedRecords: any = {};

  records.forEach((record) => {
    record.weight = parseFloat(record.weight.toFixed(2));
    const {
      etf,
      "factor.asset_class": assetClass,
      "factor.Style": style,
      "factor.Sector": sector,
      weight,
      objective_function,
    } = record;

    const key = assetClass;

    // constituentsOrder.forEach((item) => {

    if (combinedRecords[key]) {
      combinedRecords[key].weight += weight;

      combinedRecords[key].etf += `, ${etf}`;
      combinedRecords[key].currentAllocation = 0;
      combinedRecords[key].delta = parseFloat(
        combinedRecords[key].weight.toFixed(2)
      );
      combinedRecords[key].objective_function = objective_function;
    } else {
      combinedRecords[key] = {
        etf,
        "factor.asset_class": assetClass,
        "factor.Style": style,
        "factor.Sector": sector,
        weight,
        currentAllocation: 0,
        delta: weight,
        objective_function,
      };
    }
    combinedRecords[key].weight = parseFloat(
      combinedRecords[key].weight.toFixed(2)
    );
  });
  const combined = combinedRecords;
  constituentsOrder.forEach((element) => {
    const index = Object.values(combinedRecords).findIndex((item: any) => {
      return item["factor.asset_class"] === element;
    });
    if (index === -1) {
      combined[element] = {
        etf: "",
        "factor.asset_class": element,
        "factor.Style": "",
        "factor.Sector": "",
        weight: 0,
        currentAllocation: 0,
        delta: 0,
      };
    }
  });
  return Object.values(combined);
};
export const combineWeightsByAssetClass = (
  records: ETFRecord[]
): ETFRecord[] => {
  const combinedRecords: any = {};

  records.forEach((record) => {
    record.weight = parseFloat(record.weight.toFixed(2));
    const {
      etf,
      "factor.asset_class": assetClass,
      "factor.Style": style,
      "factor.Sector": sector,
      weight,
      objective_function,
    } = record;

    const key = assetClass;

    // constituentsOrder.forEach((item) => {

    if (combinedRecords[key]) {
      combinedRecords[key].weight += weight;

      combinedRecords[key].etf += `, ${etf}`;
      combinedRecords[key].currentAllocation = 0;
      combinedRecords[key].delta = parseFloat(
        combinedRecords[key].weight.toFixed(2)
      );
      combinedRecords[key].objective_function = objective_function;
    } else {
      combinedRecords[key] = {
        etf,
        "factor.asset_class": assetClass,
        "factor.Style": style,
        "factor.Sector": sector,
        weight,
        currentAllocation: 0,
        delta: weight,
        objective_function: objective_function,
      };
    }
    combinedRecords[key].weight = parseFloat(
      combinedRecords[key].weight.toFixed(2)
    );
  });

  return Object.values(combinedRecords);
};
interface ETFRecord {
  etf: string;
  "factor.asset_class": string;
  "factor.Style"?: string;
  "factor.Sector"?: string;
  weight: number;
  objective_function: string;
}

export const assetClassColumns: TableColumns[] = [
  {
    dataField: "factor.asset_class",
    text: "Asset Class",
  },
  {
    dataField: "etf",
    text: "ETF",
  },

  {
    dataField: "weight",
    text: "Suggested Allocation",
  },
  {
    dataField: "currentAllocation",
    text: "Current Allocation",
  },
  {
    dataField: "delta",
    text: "Delta",
  },
];

export const etfColumns: TableColumns[] = [
  {
    dataField: "etf",
    text: "ETF",
  },
  {
    dataField: "factor.asset_class",
    text: "Asset Class",
  },

  {
    dataField: "weight",
    text: "Suggested Allocation",
  },
];

export const getFormattedDateTime = () => {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return date.toLocaleString("en-US", options).replace(/[\\/\s,:]/g, "_");
};
