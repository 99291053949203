import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getInvestPortfolio,
  getPortfolioByCustomerID,
} from "../../apis/questionnaireResponseApi";
import { HomeHeader } from "../../components/Home/Header/HomeHeader";
import { useUser } from "../../store/hooks/useUser";
import { Portfolio } from "../../typings/types";
import { About } from "./Sections/About/About";
import { Compare } from "./Sections/Compare/Compare";
import { Holdings } from "./Sections/Holdings/Holdings";
import { Overview } from "./Sections/Overview/Overview";
import { Reset } from "./Sections/Reset/Reset";
import initialDashboardData from "components/initialDashboardData";
import { useRisk } from "store/hooks/useRisk";
import { EMAIL, FUNDED, LOW, MEDIUM, REBALANCED } from "constants/constants";
import { useNavigate } from "react-router-dom";
import { Dashboard, ModalPortfolioInfo } from "typings/HomePage/types/types";
// import { boot as bootIntercom } from "../../intercom.js";
import { Button, Modal } from "react-bootstrap";
import { changePortfolioVersion } from "apis/userApi";

const getDimensions = (element: HTMLElement) => {
  const { height } = element.getBoundingClientRect();
  const offsetTop = element.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

export const separator = (numb: number | string) => {
  const str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
};

export const Home = React.memo(() => {
  const { currentUser } = useUser();
  // if (currentUser) {
  //   bootIntercom({
  //     email: sessionStorage.getItem(EMAIL),
  //     name: currentUser.name,
  //   });
  // } else {
  //   bootIntercom();
  // }
  const [chartData, setChartData] = useState<Portfolio | undefined>();
  const [visibleSection, setVisibleSection] = useState<string | null>();
  const [secHeight, setSecHeight] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [dashboardData, setDashboardData] =
    useState<Dashboard>(initialDashboardData);
  const { setRisk } = useRisk();
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [versionShow, setVersionShow] = useState<boolean>(false);
  const [portfolioInfo, setPortfolioInfo] = useState<ModalPortfolioInfo>();
  const [showRebalance, setShowRebalance] = useState<boolean>(false);

  const headerRef = useRef(null);
  const overviewRef = useRef(null);
  const aboutRef = useRef(null);
  const holdingRef = useRef(null);
  const compareRef = useRef(null);
  const resetRef = useRef(null);

  const sectionRefs = useMemo(
    () => [
      { section: "Overview", ref: overviewRef },
      { section: "About", ref: aboutRef },
      { section: "Holding", ref: holdingRef },
      { section: "Compare", ref: compareRef },
      { section: "Reset", ref: resetRef },
    ],
    []
  );

  const sectionScroll = useCallback(
    (element: HTMLElement) => {
      const headerOffset = secHeight - 2;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    [secHeight]
  );

  const handleScroll = useCallback(() => {
    if (headerRef.current) {
      const { height: headerHeight } = getDimensions(headerRef.current);
      setSecHeight(headerHeight);
      const scrollPosition = window.scrollY + (headerHeight + 1);

      const selected = sectionRefs.find(({ ref }) => {
        const element = ref.current;
        if (element) {
          const { offsetBottom, offsetTop } = getDimensions(element);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return undefined;
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    }
  }, [sectionRefs, visibleSection]);

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, sectionRefs, visibleSection]);

  const setDashboard = useCallback(
    async (investResponse: Dashboard) => {
      const portfolio = await getPortfolioByCustomerID(
        investResponse.customerPortfolioId
      );
      if (portfolio) {
        setDashboardData(() => investResponse);
        setChartData(portfolio);
        setRisk(portfolio.portfolioFitments[0].riskProfile);
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    },
    [setRisk]
  );

  const fetchPortfolioData = useCallback(async () => {
    try {
      const res = await getInvestPortfolio();
      if (res === undefined) {
        navigate("/profiling");
        return;
      } else {
        if (res) {
          const investResponse = res;
          if (investResponse.status === FUNDED) {
            const res = await getPortfolioByCustomerID(
              investResponse.customerPortfolioId
            );
            setChartData(res);
            setShow(true);
            setIsDisabled(true);
          } else {
            setDashboard(investResponse);
            if (investResponse.status === REBALANCED) {
              setShowRebalance(true);
            }
          }
          if (investResponse.showVersionPopup) {
            setVersionShow(true);
            setPortfolioInfo(investResponse);
          }
        } else {
          setIsDisabled(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchPortfolioData();
  }, [fetchPortfolioData]);

  const handleOverviewClick = useCallback(() => {
    overviewRef.current && sectionScroll(overviewRef.current);
  }, [sectionScroll]);

  const handleHoldingsClick = useCallback(() => {
    holdingRef.current && sectionScroll(holdingRef.current);
  }, [sectionScroll]);

  const handleCompareClick = useCallback(() => {
    compareRef.current && sectionScroll(compareRef.current);
  }, [sectionScroll]);

  const handleResetClick = useCallback(() => {
    resetRef.current && sectionScroll(resetRef.current);
  }, [sectionScroll]);

  const handleAboutClick = useCallback(() => {
    aboutRef.current && sectionScroll(aboutRef.current);
  }, [sectionScroll]);

  const handleMenuMobile = useCallback(() => {
    setMobileMenu(!mobileMenu);
  }, [mobileMenu]);

  const handleClose = () => setShow(false);
  const handleVersionClose = () => setVersionShow(false);
  const handleRebalanceClose = () => setShowRebalance(false);

  const handleClick = (version: number | undefined) => {
    if (version && portfolioInfo) {
      changePortfolioVersion(portfolioInfo.portfolioId, version);
      setVersionShow(false);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="dashboard_header" id="head_section" ref={headerRef}>
        <HomeHeader />

        <div className="dashboard_head menu_container">
          <div className="container">
            <div className="dashboard_user_name">
              {currentUser !== null ? (
                <h4>{`${currentUser.name}'s Portfolio`}</h4>
              ) : null}

              <div
                className={`slide_nav_section ${
                  chartData?.portfolioFitments[0].riskProfile === LOW
                    ? "low_slide"
                    : chartData?.portfolioFitments[0].riskProfile === MEDIUM
                    ? "medium_slide"
                    : "high_slide"
                }`}>
                <div className="slide_circle_indicate">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p>{chartData?.portfolioFitments[0].riskProfile} Risk</p>
              </div>
            </div>

            <div className="mob_dashboard_menu">
              <div className="menu_icon">
                <button className="btn" onClick={handleMenuMobile}>
                  <img src="/images/icons/menu.png" alt="menu" />
                </button>
              </div>
              <div
                className={`dashboard_menu ${
                  mobileMenu ? "" : "dashboard_menu_hide"
                }`}>
                <div className="dashboard_menu_list">
                  <ul>
                    <li
                      className={`${
                        visibleSection === "Overview" ? "link-active" : ""
                      }`}
                      onClick={handleMenuMobile}>
                      <button
                        disabled={isDisabled}
                        onClick={handleOverviewClick}>
                        Overview
                      </button>
                    </li>

                    <li
                      className={`${
                        visibleSection === "About" ? "link-active" : ""
                      }`}
                      onClick={handleMenuMobile}>
                      <button onClick={handleAboutClick}>
                        Your Model portfolio
                      </button>
                    </li>
                    <li
                      className={`${
                        visibleSection === "Holding" ? "link-active" : ""
                      }`}
                      onClick={handleMenuMobile}>
                      <button
                        disabled={isDisabled}
                        onClick={handleHoldingsClick}>
                        Holdings
                      </button>
                    </li>
                    <li
                      className={`${
                        visibleSection === "Compare" ? "link-active" : ""
                      }`}
                      onClick={handleMenuMobile}>
                      <button
                        disabled={isDisabled}
                        onClick={handleCompareClick}>
                        Compare
                      </button>
                    </li>
                    <li
                      className={`${
                        visibleSection === "Reset" ? "link-active" : ""
                      }`}
                      onClick={handleMenuMobile}>
                      <button disabled={isDisabled} onClick={handleResetClick}>
                        Reset
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {/* {isDisabled ? <Review /> : null} */}

      {!isDisabled && (
        <section className="overview_section" id="Overview" ref={overviewRef}>
          <Overview
            overViewValue={dashboardData.aggregatedPortfolioStatsByAsOfDate}
          />
        </section>
      )}

      {chartData ? (
        <section className="about_section" id="About" ref={aboutRef}>
          <About
            dashboardPieGraph={{
              pieData: chartData.holdings,
              exp: chartData.expectedReturn,
              best: chartData.bestOutcomeRange,
              worst: chartData.worstOutcomeRange,
              desc: chartData.portfolioDescription,
            }}
            lineData={chartData.portfolioStats}
          />
        </section>
      ) : null}

      {!isDisabled && (
        <>
          <section className="holding_section" id="Holding" ref={holdingRef}>
            <Holdings
              holdingTable={dashboardData.customerSecurityHoldingsByAsOfDate}
            />
          </section>

          <section className="compare_section" id="Compare" ref={compareRef}>
            <Compare
              compareValue={{
                portfolioValue:
                  dashboardData.aggregatedPortfolioStatsByAsOfDate,
                benchMark: dashboardData.benchMarkByAsOfDate,
              }}
            />
          </section>

          <section className="reset_section" id="Reset" ref={resetRef}>
            <Reset />
          </section>
        </>
      )}

      {/* Order confirmed popup */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">
              <img src="/images/logo.png" width={100} alt="logo" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          Your order has been sent for Execution. Please check back after 24-48
          hours for your investment and holding data
        </Modal.Body>
      </Modal>

      {/* rebalance status info  */}

      <Modal show={showRebalance} onHide={handleRebalanceClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">
              <img src="/images/logo.png" width={100} alt="logo" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          You have chosen to rebalance your portfolio to the latest version. For
          the new portfolio details, please review the "Your Model Portfolio"
          tab . For the latest investment and holding data, check back after
          24-48 hours
        </Modal.Body>
      </Modal>

      {/* Rebalanced option popup */}
      <Modal responsive show={versionShow} onHide={handleVersionClose}>
        <Modal.Header closeButton>
          <Modal.Title>Portfolio Rebalance Update !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            WealthZen Investment team has made some changes to your model
            portfolio based on their detailed research about the current macro
            outlook. We recommend you rebalance your portfolio to incorporate
            these changes. But you are free to make a choice and keep on with
            the older version.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn primary_btn yes_btn"
            onClick={() => handleClick(portfolioInfo?.newVersion)}>
            Rebalance
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleClick(portfolioInfo?.currentVersion)}>
            Don't change
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
