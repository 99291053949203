import React, { useCallback, useState } from "react";
import { AdminHeader } from "../../../components/Admin/Header/AdminHeader";
import { fileUpload } from "../../../apis/adminPortalApi";
import { validationError } from "typings/AdminPortal/types/types";
import { STATUS_FAILURE_400, STATUS_SUCCESS_200 } from "constants/constants";
import { ProgressBar } from "react-bootstrap";

export const PortfolioUpload = React.memo(() => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<validationError>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFailure, setIsFailure] = useState<string>("");

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setFile(event.target.files[0]);
      }
      setUploadProgress(0);
      setIsError(false);
      setIsSuccess(false);
      setIsFailure("");
    },
    []
  );

  const fileUploadProgress = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      if (percent <= 100) {
        setUploadProgress(percent);
      }
    },
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      const response = await fileUpload(formData, fileUploadProgress);
      if (response?.status === STATUS_SUCCESS_200) {
        if (response?.data.length > 0) {
          setValidationErrors(response?.data);
          setIsError(true);
        } else {
          setIsSuccess(true);
        }
      } else if (response?.error.status === STATUS_FAILURE_400) {
        setIsFailure(response.error.description);
      } else {
        setIsFailure(response.error);
      }
    }
    setUploadProgress(0);
    setFile(null);
  };

  return (
    <div className="background_color">
      <AdminHeader />
      <div className="container ">
        <div className="m-4 row ">
          <div className="col-6">
            <div className="">
              <div className="form-group">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="form-control"
                />
                <div className="col-12 mt-3">
                  {uploadProgress > 0 && (
                    <ProgressBar
                      animated
                      now={uploadProgress}
                      label={`${uploadProgress}%`}
                    />
                  )}
                </div>
                <button
                  type="button"
                  onClick={handleFileUpload}
                  className="btn btn-primary mt-4">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        {isError && (
          <div className="m-4 row ">
            <h3>Validation Errors</h3>
            <table className="table">
              <tbody>
                {validationErrors?.map((err) => {
                  return (
                    <tr>
                      <td className="error text-capitalize fs-5 col-md-6">
                        {err.message}
                      </td>
                      <td className="col-md-6">{err.value}</td>
                    </tr>
                  );
                })}{" "}
              </tbody>
            </table>
          </div>
        )}
        {isSuccess && (
          <div className="m-4 row text-success">
            <h4>File uploaded successfully</h4>
          </div>
        )}
        {isFailure && (
          <div className="m-4 row text-danger">
            <h4>File upload failed.</h4>
            <p>{isFailure}</p>
          </div>
        )}
      </div>
    </div>
  );
});
