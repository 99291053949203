import { ResponsiveLine } from "@nivo/line";
import React from "react";
import { WEALTHZEN_PORTFOLIO } from "../../constants/constants";
import { LineData } from "../../typings/Charts/types/types";

type LineChartProps = {
  lineData: LineData[];
};

export const LineChart = React.memo<LineChartProps>(({ lineData }) => {
  const data = [
    {
      id: "BenchMark",
      color: "#543979",
      data: lineData.map((element: LineData) => ({
        x: element.statsAsOfDate,
        y: parseFloat(element.benchmarkValueUSD.replace(",", "")).toFixed(2),
      })),
    },
    {
      id: "WealthZen Portfolio",
      color: "#D95E73",
      data: lineData.map((element: LineData) => ({
        x: element.statsAsOfDate,
        y: parseFloat(element.portfolioUnitValueUSD.replace(",", "")).toFixed(
          2
        ),
      })),
    },
  ];
  return (
    <ResponsiveLine
      data={data}
      tooltip={({ point }) => {
        const { data } = point;
        return (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
            }}>
            <div className="line_tooltip_txt">
              {point.serieId === WEALTHZEN_PORTFOLIO ? (
                <div>
                  <p>
                    Date: <span>{data.xFormatted}</span>
                  </p>
                  <p>
                    Value: <span>${data.yFormatted}</span>
                  </p>
                </div>
              ) : (
                <div className="benchMark_label">
                  <p>
                    Date: <span>{data.xFormatted}</span>
                  </p>
                  <p>
                    Value: <span>${data.yFormatted}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      }}
      margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 6,
        tickPadding: 10,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        format: (value) => {
          return "$ " + value;
        },
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      colors={(data) => data.color}
      lineWidth={3}
      enableGridX={false}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "Accent" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "top-right",
          direction: "row",
          justify: false,
          translateX: 50,
          translateY: -36,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 130,
          itemHeight: 10,
          itemOpacity: 0.75,
          symbolSize: 10,
          symbolShape: "circle",
          symbolBorderColor: "rgba(200, 200, 200)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
              },
            },
          ],
        },
      ]}
    />
  );
});
