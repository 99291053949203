import { AppState } from "../../typings/types";
import { useAppContext } from "../context";

export const useRisk = () => {
  const [state, setState] = useAppContext();

  const getRisk = () => {
    return state.riskProfile;
  };

  const getInitialRisk = () => {
    return state.initialRiskProfile;
  };
  const setPortfolioId = (id: string) => {
    setState((prev: AppState) => ({ ...prev, portfolioId: id }));
  };
  const setCustomization = async (data: { [key: string]: string }) => {
    setState((prev: AppState) => {
      const newStates = { ...prev, ...data };
      sessionStorage.setItem("appState", JSON.stringify(newStates));
      return newStates;
    });
  };

  const setRisk = (data: string | null) => {
    setState((prev: AppState) => {
      const newStates = { ...prev, riskProfile: data };
      sessionStorage.setItem("appState", JSON.stringify(newStates));
      return newStates;
    });
  };

  const setInitialRisk = (data: string | null) => {
    setState((prev: AppState) => {
      const newStates = { ...prev, initialRiskProfile: data };
      sessionStorage.setItem("appState", JSON.stringify(newStates));
      return newStates;
    });
  };

  const setDisclaimer = () => {
    setState((prev: AppState) => {
      const newStates = { ...prev, disclaimer: !prev.disclaimer };
      sessionStorage.setItem("appState", JSON.stringify(newStates));
      return newStates;
    });
  };
  return {
    currentRisk: state.riskProfile,
    performance: state.performance,
    theme: state.theme,
    portfolioId: state.portfolioId,
    getRisk,
    setRisk,
    setInitialRisk,
    getInitialRisk,
    setCustomization,
    setPortfolioId,
    setDisclaimer,
  };
};
