import { UpdatedDate } from "components/UpdatedDate";
import { BITCOIN, FIXED_DEPOSIT } from "constants/constants";
import { separator } from "pages/HomePage/Home";
import React from "react";
import {
  AggregatedPortfolioStats,
  AggregatedPortfolioStatsByDate,
  BenchMark,
  BenchMarkAsByDate,
} from "typings/HomePage/types/types";

type CompareProps = {
  compareValue: {
    portfolioValue: AggregatedPortfolioStatsByDate;
    benchMark: BenchMarkAsByDate;
  };
};

export const Compare = React.memo<CompareProps>(({ compareValue }) => {
  const portfolioValue: AggregatedPortfolioStats[] = Object.values(
    compareValue.portfolioValue
  )[0];
  const portfolioData: AggregatedPortfolioStats = portfolioValue[0];
  const benchMarkValue: BenchMark[] = Object.values(compareValue.benchMark)[0];
  const benchmarkDate: string = portfolioData.asOfDate;
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <UpdatedDate date={benchmarkDate} />
        </div>
        <div className="col-12">
          <div className="compare_value_block">
            <div className="compare_value_content">
              <img src="./images/icons/home-img3.png" alt="" />
              <div>
                <h4>Portfolio Value</h4>
              </div>
            </div>
            <div className="compare_value">
              <h3>$ {separator(portfolioData.currentValue)}</h3>
            </div>
          </div>
          <div className="compare_more_txt">
            <p>
              If you had invested in the below instruments, your investment
              would be worth ...
            </p>
          </div>
          <div className="compare_portfolio_list">
            <ul>
              {benchMarkValue.map((EachBenchMark: BenchMark) => {
                return EachBenchMark.benchmarkName === BITCOIN ? (
                  <li>
                    <div className="compare_portfolio_list_img">
                      <img src="./images/icons/bitcoin-img.png" alt="" />
                      <p>{EachBenchMark.benchmarkName}</p>
                    </div>
                    <div>
                      <h4>
                        <span>$</span>{" "}
                        {separator(EachBenchMark.benchmarkCurrentValue)}
                      </h4>
                    </div>
                  </li>
                ) : EachBenchMark.benchmarkName === FIXED_DEPOSIT ? (
                  <li>
                    <div className="compare_portfolio_list_img">
                      <img src="./images/icons/bulkcoin-img.png" alt="" />
                      <p>{EachBenchMark.benchmarkName}</p>
                    </div>
                    <div>
                      <h4>
                        <span>$</span>{" "}
                        {separator(EachBenchMark.benchmarkCurrentValue)}
                      </h4>
                    </div>
                  </li>
                ) : (
                  <li>
                    <div className="compare_portfolio_list_img">
                      <img src="./images/icons/coinindex-img.png" alt="" />
                      <p>{EachBenchMark.benchmarkName}</p>
                    </div>
                    <div>
                      <h4>
                        <span>$</span>{" "}
                        {separator(EachBenchMark.benchmarkCurrentValue)}
                      </h4>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
