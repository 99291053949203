import questions from "store/questionsData";
import { AppState, Option, Question } from "../../typings/types";
import { useAppContext } from "../context";

export const useQuestions = () => {
  const [state, setState] = useAppContext();
  const setSectionDone = (section: string, id: number, newSection: string) => {
    let newState = { ...state };
    let { questions: ques } = newState;
    let secData = ques[section];
    secData = secData.map((element: Question) => ({
      ...element,
      done: true,
    }));
    ques = { ...ques, [section]: secData };
    newState = { ...state, questions: ques };

    const { questions: quest } = newState;
    const questions = {
      ...quest,
      meta: {
        currentId: id,
        currentSection: newSection,
      },
    };

    newState = { ...state, questions };

    sessionStorage.setItem("appState", JSON.stringify(newState));
    setState(newState);
  };
  const getQuestion = () => {
    const { meta } = state.questions;
    if (meta?.currentSection && meta?.currentId) {
      return state.questions[meta.currentSection]?.filter(
        (element: Question) => element.id === meta.currentId
      )[0];
    }
    return null;
  };

  const getSectionQuestions = () => {
    const { meta } = state.questions;
    if (meta?.currentSection) {
      return state.questions[meta.currentSection];
    }
    return null;
  };

  const saveAnswer = (data: Question) => {
    const newState = { ...state };
    const { questions: ques } = newState;
    const { meta } = ques;

    ques[meta.currentSection] = ques[meta.currentSection].map(
      (element: Question) => {
        if (element.id === meta.currentId) {
          return data;
        }
        return element;
      }
    );

    sessionStorage.setItem("appState", JSON.stringify(newState));
    setState(newState);
  };

  const setQuestion = (id: number, section: string, start = false) => {
    const { questions: ques } = state;

    const { currentId, currentSection } = ques.meta;

    const value =
      ques[currentSection]
        ?.find((element: Question) => element.id === currentId)
        ?.options.find((element: Option) => element.selected)?.value || 0;

    let { points } = ques.meta;

    points = (points ? points : 0) + Number(value);

    ques.meta.points = points;
    const questions = {
      ...ques,
      meta: {
        ...ques.meta,
        currentId: id,
        currentSection: section,
      },
    };

    const onStart = start
      ? {
          progress: !state.progress,
          quizSec: !state.quizSec,
          headerTxt: !state.headerTxt,
          start: true,
        }
      : {};

    setState((prev: AppState) => {
      const newS = { ...prev, questions, ...onStart };
      sessionStorage.setItem("appState", JSON.stringify(newS));
      return newS;
    });
  };

  const resetQuestion = () => {
    const questionReset = questions;

    setState((prev: AppState) => {
      const newS = { ...prev, questions: questionReset };
      sessionStorage.setItem("appState", JSON.stringify(newS));
      return newS;
    });
  };

  return {
    questions: state.questions,
    setSectionDone,
    getQuestion,
    getSectionQuestions,
    setQuestion,
    saveAnswer,
    resetQuestion,
  };
};
