import React from "react";
import { RingLoader } from "react-spinners";

interface LoadingProps {
  size?: number;
  thickness?: number;
}

const Loader: React.FC<LoadingProps> = ({ size = 50, thickness = 4 }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}>
      <RingLoader color={"#d95e73"} size={size}></RingLoader>{" "}
      <div className="title_color ps-3">Optimizing</div>
    </div>
  );
};

export default Loader;
