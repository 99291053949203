import React, { useState, useEffect, useCallback } from "react";
import { HomeHeader } from "../../components/Home/Header/HomeHeader";
import QusProgress from "../../components/QusProgress";
import StartPage from "./StartPage";
import { useAppContext } from "../../store/context";
import { useQuestions } from "../../store/hooks/useQuestion";
import QuestionBlock from "./QuestionBlock";
import questionData from "../../store/questionsData";
import { useSessionStorage } from "../../store/hooks/useSessionStorage";
import { useRisk } from "../../store/hooks/useRisk";
import { useUser } from "../../store/hooks/useUser";
import SideNav from "../../components/SideNav";
import * as XLSX from "xlsx";
import {
  getSuggestedPortfolio,
  postInvestPortfolio,
} from "../../apis/questionnaireResponseApi";
import { InvestPortfolioPayload, Option, Question } from "../../typings/types";
import {
  CURRENT_ALLOCATION,
  FINANCIAL_KNOWLEDGE,
  INVEST,
  INVESTMENT_PORTFOLIO_BEFORE_LOGIN_Q_ID,
  INVESTMENT_PORTFOLIO_Q_ID,
  I_AM_NOT_SURE,
  OBJECTIVE_FUNCTION,
  OPTIMIZED_DATA,
  USER_FLOW_FIRST_QUESTION,
} from "../../constants/constants";
import { usePortfolio } from "store/hooks/usePortfolio";
import Loader from "components/Loaders/Loader";
import {
  assetClassColumns,
  combineWeightsByAllAssetClass,
  combineWeightsByAssetClass,
  etfColumns,
  calculateSum as filterByObjectiveFunction,
  removeNegativeValues,
} from "util/utils";

const UserJourney = () => {
  const {
    questions,
    getQuestion,
    getSectionQuestions,
    setQuestion,
    setSectionDone,
  } = useQuestions();

  const [state] = useAppContext();

  const { progress, quizSec } = state;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentQuestion = getQuestion();

  const currentSectionQuestions = getSectionQuestions();

  const [clientData, setClientData] = useState<{
    clientEmail: string;
    clientName: string;
    date: string;
  }>();

  const nextQuestion = async () => {
    const data = sessionStorage.getItem("clientResponse");
    if (data) {
      const clientInfo = JSON.parse(data);
      setClientData(clientInfo);
    }
    const { meta } = questions;

    const next = currentQuestion.next;

    const selectedOption = currentQuestion?.options.filter(
      (element: Option) => element.selected
    );
    if (selectedOption && selectedOption.length > 0) {
      if (selectedOption[0]?.skipTo) {
        setSectionDone(
          meta.currentSection,
          selectedOption[0].skipTo.id,
          selectedOption[0].skipTo.section
        );

        return true;
      }
    }

    if (currentQuestion.isSectionEnd && clientData) {
      setIsLoading(true);

      let obj = {
        current_regime_date: clientData?.date,
        growth: 0,
        inflation: 0,
        risk: 0,
        risk_appetite: null,
        objective_functions: [],
      };

      for (const i in currentSectionQuestions) {
        if (currentSectionQuestions[i].isRequired) {
          if (currentSectionQuestions[i].questionNo === "Q1") {
            obj.risk_appetite = currentSectionQuestions[i].value;
          } else if (currentSectionQuestions[i].questionNo === "Q2") {
            obj.growth = currentSectionQuestions[i].value;
          } else if (currentSectionQuestions[i].questionNo === "Q3") {
            obj.inflation = currentSectionQuestions[i].value;
          } else if (currentSectionQuestions[i].questionNo === "Q4") {
            obj.risk = currentSectionQuestions[i].value;
          } else if (currentSectionQuestions[i].questionNo === "Q5") {
            if (Array.isArray(currentSectionQuestions[i].value)) {
              obj.objective_functions = currentSectionQuestions[i].value;
            }
          }
        }
      }

      const [res, error] = await getSuggestedPortfolio(obj);
      if (res) {
        setIsLoading(false);

        let dataFilteredByObjFun: any = {};
        const [objectiveFun, filteredData] = removeNegativeValues(res);

        objectiveFun.forEach((element: string) => {
          const dataByObjectFunction = filterByObjectiveFunction(
            filteredData,
            element
          );
          dataFilteredByObjFun[element] = {
            pieData: combineWeightsByAssetClass(dataByObjectFunction),
            assetClassBased:
              combineWeightsByAllAssetClass(dataByObjectFunction),
            etfBased: filterByObjectiveFunction(dataByObjectFunction, element),
          };
        });
        sessionStorage.setItem(
          OBJECTIVE_FUNCTION,
          JSON.stringify(objectiveFun)
        );

        sessionStorage.setItem(
          OPTIMIZED_DATA,
          JSON.stringify(dataFilteredByObjFun)
        );

        if (next) {
          setQuestion(next.id, next.section);
          return;
        }
        setQuestion(meta.currentId + 1, meta.currentSection);
      } else {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      if (next) {
        setQuestion(next.id, next.section);
        return;
      }
      setQuestion(meta.currentId + 1, meta.currentSection);
    }
  };

  const assignCurrentAllocations = (
    assetClassData: any,
    currentAllocations: any
  ) => {
    return assetClassData.map((record: any) => {
      const assetClass = record["factor.asset_class"];
      const allocationData = currentAllocations[assetClass] || {};

      return {
        ...record,
        currentAllocation: allocationData.enteredValue || 0,
        delta: allocationData.delta || 0,
      };
    });
  };
  const assignEmptyCurrentAllocations = (assetClassData: any) => {
    return assetClassData.map((record: any) => {
      return {
        ...record,
        currentAllocation: 0,
        delta: 0,
      };
    });
  };
  const getPrintData = (data: any) => {
    let printData: any[] = [];

    Object.keys(data).forEach((x, index) => {
      if (x === "annualised_return") {
        printData = printData.concat([
          [],
          [],
          [],

          ["Optimisation method", "Maximize Annual Return"],

          [],
          [],
        ]);
      } else if (x === "volatility") {
        printData = printData.concat([
          [],
          [],
          [],
          ["Optimisation method", "Minimize Volatility"],

          [],
          [],
        ]);
      } else if (x === "sharpe_ratio") {
        printData = printData.concat([
          [],
          [],
          [],
          ["Optimisation method", "Sharpe Ratio"],

          [],
          [],
        ]);
      }
      const assetClassHeaders = assetClassColumns.map(
        (column: { text: any }) => column.text
      );
      const etfHeaders = etfColumns.map((column: { text: any }) => column.text);

      let assetData: any[] = [...assetClassHeaders];
      let etfData: any[] = [...etfHeaders];

      Object.keys(data[x]).forEach((item: string | number) => {
        if (item === "assetClassBased") {
          assetData = data[x][item].map((row: { [x: string]: any }) =>
            assetClassColumns.map(
              (column: { dataField: string | number }) => row[column.dataField]
            )
          );
        } else if (item === "etfBased") {
          etfData = data[x][item].map((row: { [x: string]: any }) =>
            etfColumns.map(
              (column: { dataField: string | number }) => row[column.dataField]
            )
          );
        }
      });
      printData = printData.concat([[...assetClassHeaders]]);
      printData = printData.concat(assetData);
      printData = printData.concat([[], [], [], [...etfHeaders]]);
      printData = printData.concat(etfData);

      if (index < Object.keys(data).length - 1) {
        printData = printData.concat([[]]);
      }
    });

    return printData;
  };

  interface Column {
    text: string;
    dataField: string | number;
  }

  interface Row {
    [key: string]: any;
  }

  interface Data {
    [key: string]: {
      assetClassBased: Row[];
      etfBased: Row[];
    };
  }

  interface PrintDataItem {
    text?: string;
    bold?: boolean;
  }

  // const getPrintData = (data: any) => {
  //   let printData: any[] = [];

  //   // Sort the keys based on the desired order (e.g., "annualised_return", "volatility", "sharpe_ratio")
  //   const orderedKeys: string[] = [
  //     "annualised_return",
  //     "volatility",
  //     "sharpe_ratio",
  //   ];

  //   // Sort the data based on the objectiveFunction order
  //   const sortedData: Data = orderedKeys.reduce((sorted, key) => {
  //     if (data[key]) {
  //       sorted[key] = data[key];
  //     }
  //     return sorted;
  //   }, {} as Data);

  //   Object.keys(sortedData).forEach((x, index) => {
  //     if (x === "annualised_return") {
  //       printData = printData.concat([
  //         [],
  //         [],

  //         [
  //           { text: "Optimisation method", bold: true },
  //           { text: "Maximize Annual Return" },
  //         ],
  //         [],
  //         [],
  //         [],
  //       ]);
  //     } else if (x === "volatility") {
  //       printData = printData.concat([
  //         [],
  //         [],
  //         [
  //           { text: "Optimisation method", bold: true },
  //           { text: "Minimize Volatility" },
  //         ],
  //         [],
  //         [],
  //         [],
  //       ]);
  //     } else if (x === "sharpe_ratio") {
  //       printData = printData.concat([
  //         [],
  //         [],
  //         [
  //           { text: "Optimisation method", bold: true },
  //           { text: "Sharpe Ratio" },
  //         ],
  //         [],
  //         [],
  //         [],
  //       ]);
  //     }

  //     const assetClassHeaders = assetClassColumns.map((column) => column.text);
  //     const etfHeaders = etfColumns.map((column) => column.text);

  //     let assetData: Row[] = [];
  //     let etfData: Row[] = [];

  //     Object.keys(sortedData[x]).forEach((item: string) => {
  //       if (item === "assetClassBased") {
  //         assetData = [...assetData, ...sortedData[x][item]];
  //       } else if (item === "etfBased") {
  //         etfData = sortedData[x][item];
  //       }
  //     });

  //     printData = printData.concat([assetClassHeaders]);
  //     printData = printData.concat(assetData);
  //     printData = printData.concat([[], [], [], [...etfHeaders]]);
  //     printData = printData.concat(etfData);

  //     if (index < Object.keys(sortedData).length - 1) {
  //       printData = printData.concat([[]]);
  //     }
  //   });
  //   console.table(printData);
  //   return printData;
  // };

  const handleExport = () => {
    const optimizedDataStr = sessionStorage.getItem(OPTIMIZED_DATA);
    const currentAllocationStr = sessionStorage.getItem(CURRENT_ALLOCATION);
    let optimizedData: { [x: string]: any };
    let currentAllocation: { [x: string]: any };
    if (optimizedDataStr) {
      optimizedData = JSON.parse(optimizedDataStr);
      if (currentAllocationStr) {
        currentAllocation = JSON.parse(currentAllocationStr);

        Object.keys(optimizedData).forEach((item) => {
          if (optimizedData[item] && currentAllocation[item]) {
            optimizedData[item].assetClassBased = assignCurrentAllocations(
              optimizedData[item].assetClassBased,
              currentAllocation[item]
            );
          }
        });
      } else {
        Object.keys(optimizedData).forEach((item) => {
          if (optimizedData[item]) {
            optimizedData[item].assetClassBased = assignEmptyCurrentAllocations(
              optimizedData[item].assetClassBased
            );
          }
        });
      }
      if (optimizedData) {
        const workbook = XLSX.utils.book_new();
        const mainReportSheetData = [
          ["Advisor", "Swapnil Mishra"],
          ["Simulation Date", new Date()],
          ["Client Name", clientData?.clientName],
          ["Client Email", clientData?.clientEmail],
          [],
          [],
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(
          mainReportSheetData.concat(getPrintData(optimizedData))
        );

        XLSX.utils.book_append_sheet(workbook, worksheet, "Optimized Result");

        const filename = `${
          clientData?.clientName
        }_${new Date()}_suggested_portfolio.xlsx`;
        XLSX.writeFile(workbook, filename);

        // console.log(`Excel file "${filename}" created successfully.`);
      }
    }
  };

  const startFresh = () => {
    sessionStorage.clear();

    window.location.reload();
  };
  useEffect(() => {
    const data = sessionStorage.getItem("clientResponse");
    if (data) {
      const clientInfo = JSON.parse(data);
      setClientData(clientInfo);
    }
  }, []);
  return isLoading ? (
    <Loader />
  ) : (
    <section style={{ marginBottom: "125px" }}>
      {/* Quiz Header */}
      <HomeHeader />
      {/* Let's Start Section */}
      <StartPage />
      {/* Progress Section */}
      <>
        <QusProgress hide={progress} />
        {/* Quiz Section */}
        <div className={`quiz_section ${quizSec ? "" : "dis-none"}`}>
          <div
            className={`user_qus_section ${
              currentQuestion?.id === INVESTMENT_PORTFOLIO_BEFORE_LOGIN_Q_ID ||
              currentQuestion?.id === INVESTMENT_PORTFOLIO_Q_ID
                ? "graph_layout"
                : ""
            }`}>
            <div className="quiz_qus_section ">
              {currentQuestion?.render ? (
                questionData[questions.meta?.currentSection].find(
                  (element: Question) =>
                    element.id === questions.meta?.currentId
                ).render
              ) : (
                <QuestionBlock key={questions.meta?.currentId} />
              )}
            </div>
          </div>
          <div className={`quiz_btn_section`}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {currentQuestion?.end ? (
                    <div className="quiz_btn">
                      <a href={`${currentQuestion.redirect}`}>
                        <button className="btn primary_btn">
                          Finish{" "}
                          <img
                            src="./images/icons/white-arrow-left.png"
                            alt="arrow-left"
                          />
                        </button>
                      </a>
                    </div>
                  ) : currentQuestion?.customNext ? (
                    <>
                      <div className="quiz_btn">
                        <button
                          className="btn primary_btn m-2"
                          type="button"
                          onClick={handleExport}>
                          Export to Excel
                        </button>{" "}
                        <button
                          className="btn secondary_btn m-2"
                          type="button"
                          onClick={startFresh}>
                          Start Fresh
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="quiz_btn">
                      <button
                        disabled={
                          !currentQuestion?.options.some(
                            (element: Option) => element.selected
                          )
                        }
                        className="btn primary_btn"
                        onClick={nextQuestion}>
                        Next{" "}
                        <img
                          src="./images/icons/white-arrow-left.png"
                          alt="arrow-left"
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="quiz_btn">
                <button
                  className="btn primary_btn"
                  type="button"
                  onClick={handleClick}>
                  Export to Excel
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </>

      <SideNav />
      {/* Modal */}
      {/* <div
        className="modal fade show"
        style={{ display: modal ? "block" : "none" }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body quiz_modal_body">
              <div className="quiz_info_popup">
                 <PopupContentA />
                <div className="quiz_btn">
                  <button className="btn primary_btn" onClick={nextQuestion}>
                    Continue
                    <img
                      src="./images/icons/white-arrow-left.png"
                      alt="arrow-left"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default React.memo(UserJourney);

// const getPrintData = (data: any) => {
//   let printData: any[] = [];
//   let annualReturn: any[] = [];
//   let volatility: any[] = [];
//   let sharpeRatio: any[] = [];
//   Object.keys(data).forEach((x, index) => {
//     const assetClassHeaders = assetClassColumns.map(
//       (column: { text: any }) => column.text
//     );
//     const etfHeaders = etfColumns.map((column: { text: any }) => column.text);
//     debugger;
//     Object.keys(data[x]).forEach((item: string | number) => {
//       if (item === "assetClassBased") {
//         if (x === "annualised_return") {
//           annualReturn = annualReturn.concat([
//             [],
//             [],
//             [],
//             [
//               { text: "Optimisation method", bold: true },
//               { text: "Maximize Annual Return", bold: true },
//             ],
//             [],
//             [],
//           ]);
//           annualReturn = annualReturn.concat([
//             [],
//             [],
//             [],
//             [...assetClassHeaders],
//           ]);
//           annualReturn = [
//             ...annualReturn,
//             data[x][item].map((row: { [x: string]: any }) =>
//               assetClassColumns.map(
//                 (column: { dataField: string | number }) =>
//                   row[column.dataField]
//               )
//             ),
//           ];
//         } else if (x === "volatility") {
//           volatility = volatility.concat([
//             [],
//             [],
//             [],
//             [
//               { text: "Optimisation method", bold: true },
//               { text: "Minimize Volatility", bold: true },
//             ],
//             [],
//             [],
//           ]);
//           volatility = volatility.concat([
//             [],
//             [],
//             [],
//             [...assetClassHeaders],
//           ]);
//           volatility = [
//             ...volatility,
//             data[x][item].map((row: { [x: string]: any }) =>
//               assetClassColumns.map(
//                 (column: { dataField: string | number }) =>
//                   row[column.dataField]
//               )
//             ),
//           ];
//         } else if (x === "sharpe_ratio") {
//           sharpeRatio = sharpeRatio.concat([
//             [],
//             [],
//             [],
//             [
//               { text: "Optimisation method", bold: true },
//               { text: "Sharpe Ratio", bold: true },
//             ],
//             [],
//             [],
//           ]);
//           sharpeRatio = sharpeRatio.concat([
//             [],
//             [],
//             [],
//             [...assetClassHeaders],
//           ]);
//           sharpeRatio = [
//             ...sharpeRatio,
//             data[x][item].map((row: { [x: string]: any }) =>
//               assetClassColumns.map(
//                 (column: { dataField: string | number }) =>
//                   row[column.dataField]
//               )
//             ),
//           ];
//         }
//       } else if (item === "etfBased") {
//         if (x === "annualised_return") {
//           annualReturn = annualReturn.concat([[], [], []]);
//           annualReturn = annualReturn.concat([[], [], [], [...etfHeaders]]);
//           annualReturn = [
//             ...annualReturn,
//             data[x][item].map((row: { [x: string]: any }) =>
//               etfColumns.map(
//                 (column: { dataField: string | number }) =>
//                   row[column.dataField]
//               )
//             ),
//           ];
//         } else if (x === "volatility") {
//           volatility = volatility.concat([[], [], []]);
//           volatility = volatility.concat([[], [], [], [...etfHeaders]]);
//           volatility = [
//             ...volatility,
//             data[x][item].map((row: { [x: string]: any }) =>
//               etfColumns.map(
//                 (column: { dataField: string | number }) =>
//                   row[column.dataField]
//               )
//             ),
//           ];
//         } else if (x === "sharpe_ratio") {
//           sharpeRatio = sharpeRatio.concat([[], [], []]);
//           sharpeRatio = sharpeRatio.concat([[], [], [], [...etfHeaders]]);
//           sharpeRatio = [
//             ...sharpeRatio,
//             data[x][item].map((row: { [x: string]: any }) =>
//               etfColumns.map(
//                 (column: { dataField: string | number }) =>
//                   row[column.dataField]
//               )
//             ),
//           ];
//         }
//       }
//     });

//     if (x === "annualised_return") {
//       printData = [...printData, ...annualReturn];
//     } else if (x === "volatility") {
//       printData = [...printData, ...volatility];
//     } else if (x === "sharpe_ratio") {
//       printData = [...printData, ...sharpeRatio];
//     }

//     if (index < Object.keys(data).length - 1) {
//       printData = printData.concat([[]]);
//     }
//   });

//   return printData;
// };
