import { WZ_BACKEND } from "../constants/env-constants";
import {
  CustomisedPortfolioType,
  FilterParameter,
  InvestPortfolioPayload,
  UserResponses,
} from "../typings/types";

export const createResponse = async (userResponses: UserResponses) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/customers", userResponses);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const updateResponse = async (userResponses: UserResponses) => {
  try {
    const res = await WZ_BACKEND().put("/v1/api/customers", userResponses);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const portfolioFilter = async (filter: FilterParameter) => {
  try {
    const url = `/v1/api/portfolio-masters/filter?performance=${filter.performance}&theme=${filter.theme}&riskProfile=${filter.riskProfile}`;
    const res = await WZ_BACKEND().get(url);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const postCustomisedPortFolio = async (
  payload: CustomisedPortfolioType
) => {
  try {
    const res = await WZ_BACKEND().put(`/v1/api/customers`, payload);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const postInvestPortfolio = async (payload: InvestPortfolioPayload) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/invest", payload);

    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const getInvestPortfolio = async () => {
  try {
    const res = await WZ_BACKEND().get("/v1/api/invest");
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const getPortfolioById = async (id: string) => {
  try {
    const res = await WZ_BACKEND().get("/v1/api/portfolio-masters/" + id);

    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const postWidgetAnswer = async (id: number, response: string) => {
  try {
    const res = await WZ_BACKEND().post(
      `/v1/api/vote/submit/${id}/${response}`
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const getPortfolioByCustomerID = async (customerId: number) => {
  try {
    const res = await WZ_BACKEND().get(
      `/v1/api/portfolio-masters/customer-portfolio/${customerId}`
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const getSuggestedPortfolio = async (userResponses: any) => {
  try {
    const res = await WZ_BACKEND().post("optimize", userResponses);
    return [res.data, null];
  } catch (error) {
    console.error(error);
    return [null, error];
  }
};
