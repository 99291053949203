import React from "react";
import { SliderData } from "typings/HomePage/types/types";
import { PieChart } from "components/Charts/PieChart";
import { LineData } from "typings/Charts/types/types";
import { PortfolioLineGraph } from "components/PortfolioLineGraph";

type AboutProps = {
  dashboardPieGraph: SliderData;
  lineData: LineData[];
};

export const About = React.memo<AboutProps>(
  ({ dashboardPieGraph, lineData }) => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="portfolio_graph_section_dashboard">
              <div className="container">
                <div>
                  <div className="risk_slide_block about_pie_chart">
                    <div className="row">
                      <div className="text-center">
                        <p>
                          You can check the target asset allocation and
                          historical performance for your chosen portfolio below
                        </p>
                      </div>
                      <div className="col-sm-12 col-lg-12 col-xl-4">
                        <PieChart holdings={dashboardPieGraph.pieData} />
                      </div>
                      <div className="col-sm-12 col-lg-12 col-xl-8">
                        <div className="risk_content_block">
                          <p>{dashboardPieGraph.desc}</p>
                          <ul>
                            <li>
                              <p>In a great year you make:</p>
                              <h4>
                                <img
                                  src="./images/icons/green-up.svg"
                                  alt="green up"
                                />
                                {dashboardPieGraph?.best}
                              </h4>
                            </li>
                            <li>
                              <p>In a bad year you make:</p>
                              <h4>
                                <img
                                  src="./images/icons/red-down.svg"
                                  alt="red down"
                                />
                                {dashboardPieGraph?.worst}
                              </h4>
                            </li>
                            <li>
                              <p>Expected Return</p>
                              <h4>
                                <img
                                  src="./images/icons/arrow-right.svg"
                                  alt="arrow right"
                                />
                                {dashboardPieGraph?.exp}
                              </h4>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <PortfolioLineGraph graphData={lineData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
