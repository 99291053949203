import { useEffect, useMemo, useState } from "react";
import { getAllModalPortfolios } from "apis/adminPortalApi";
import { Table } from "components/Table/Table";
import { PortfolioType } from "typings/VersionControl/types";
import { AdminHeader } from "../Header/AdminHeader";
import VersionControlTableHeaders from "./VersionControlTableHeaders";

const ShowVersions = () => {
  const [portfolios, setPortfolios] = useState<[PortfolioType]>();

  const portfolioTableData = useMemo(() => {
    const tableData: Array<{
      [key: string]: string | number | boolean | undefined;
    }> = [];

    portfolios?.forEach((portfolio) => {
      tableData.push({
        portfolioName: portfolio.portfolioName,
        portfolioID: portfolio.portfolioId,
        newVersion: portfolio.portfolioVersion,
        versionNotificationTriggered: portfolio.versionNotificationTriggered,
      });
    });
    return tableData;
  }, [portfolios]);

  const getAllPortfolios = async () => {
    const res = await getAllModalPortfolios();
    setPortfolios(res.data);
  };

  useEffect(() => {
    getAllPortfolios();
  }, []);

  return (
    <>
      <AdminHeader menu={true} isLogout={true} />
      <div className="d-flex overflow-auto m-3 ">
        <table className="table table-bordered holding_table">
          <Table
            columns={VersionControlTableHeaders}
            tableData={portfolioTableData}
          />
        </table>
      </div>
    </>
  );
};

export default ShowVersions;
