import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { TableColumns } from "typings/Table/types";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
type TableProps = {
  tableData: Array<{ [key: string]: string | number | boolean | undefined }>;
  columns: TableColumns[];
};

export const Table = React.memo<TableProps>(({ tableData, columns }) => {
  return (
    <BootstrapTable
      bootstrap4
      keyField="user_Id"
      data={tableData}
      columns={columns}
      bordered={false}
      striped
      noDataIndication={"No Data"}
      classes="table-borderless holding_table"
    />
  );
});
