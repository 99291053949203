import { UserRegistration } from "pages/RegistrationProcessPage/types/types";
import {
  RegisterUserBySocial,
  LoginUserBySocialType,
} from "typings/SocialAuthentication/types";

import { CustomerID, Email, LoginUser, Password } from "typings/types";
import { WZ_BACKEND } from "../constants/env-constants";

export const registerUser = async (userData: UserRegistration) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/auth/signup", userData);
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const generateOtp = async (email: Email) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/auth/generateOtp", email);
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const loginUser = async (userData: LoginUser) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/auth/login", userData);
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const loginAdmin = async (adminPassword: Password) => {
  try {
    const res = await WZ_BACKEND().post(
      `/v1/api/auth/admin/login?password=${adminPassword.password}`
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const mapAnonymousCustomer = async (customerId: CustomerID) => {
  try {
    const res = await WZ_BACKEND().post(
      `/v1/api/mapAnonymousCustomer/${customerId.customerId}`
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getAnonymousCustomer = async () => {
  try {
    const res = await WZ_BACKEND().get("/v1/api/getUserWithAnonymousCustomer");
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const getUsername = async (token: string) => {
  try {
    const res = await WZ_BACKEND().get("/v1/api/getUserName", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const registerUserBySocial = async (userData: RegisterUserBySocial) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/auth/oauth/signup", userData);

    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const loginUserBySocial = async (userData: LoginUserBySocialType) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/auth/oauth/login", userData);
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const getUserDetails = async () => {
  try {
    const res = await WZ_BACKEND().get("/v1/api/userInfo");
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const resetUserData = async () => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/reset");
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const getCustomerPortfolioDetails = async (id: string) => {
  try {
    const res = await WZ_BACKEND().get(`/v1/api/invest/${id}`);
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};

export const changePortfolioVersion = async (
  portfolioId: string,
  portfolioVersion: number
) => {
  try {
    const res = await WZ_BACKEND().post(`/v1/api/invest/change-version`, {
      portfolioId,
      portfolioVersion,
    });
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};
