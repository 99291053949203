import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLocation } from "react-router-dom";
import { useUser } from "store/hooks/useUser";
import { FACEBOOK_APP_ID } from "../../constants/env-constants";
import {
  FacebookResponse,
  SocialAuthProps,
} from "typings/SocialAuthentication/types";

import { EMAIL, FACEBOOK, LOGIN } from "../../constants/constants";

const FacebookAuth = React.memo<SocialAuthProps>(
  ({ registerUser, loginUser }) => {
    const { pathname } = useLocation();

    const path = pathname.split("/")[1];

    const { getCustomerId } = useUser();

    const facebook = (facebookResponse: FacebookResponse) => {
      sessionStorage.setItem(EMAIL, facebookResponse.email);
      if (getCustomerId() && path !== LOGIN) {
        registerUser({
          email: facebookResponse.email,
          providerId: facebookResponse.userID,
          imageUrl: facebookResponse.picture.data.url,
          name: facebookResponse.name,
          provider: FACEBOOK,
          anonymousCustomerId: getCustomerId(),
        });
      } else {
        loginUser({
          email: facebookResponse.email,
          providerId: facebookResponse.userID,
          imageUrl: facebookResponse.picture.data.url,
          name: facebookResponse.name,
          provider: FACEBOOK,
        });
      }
    };

    return (
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        scope="public_profile,email"
        callback={facebook}
        cssClass="facebook"
        render={(renderProps) => (
          <button onClick={renderProps.onClick}>
            <img src="./images/icons/fb.png" alt="Facebook Icon" />
            <span>Facebook</span>
          </button>
        )}
      />
    );
  }
);

export default FacebookAuth;
