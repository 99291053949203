import React, { useCallback, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { generateOtp, getUserDetails, loginUser } from "../../../apis/userApi";
import { useQuestions } from "../../../store/hooks/useQuestion";
import { useSessionStorage } from "../../../store/hooks/useSessionStorage";
import { RegistrationHeader } from "components/Registration/Header/RegistrationHeader";
import { useRisk } from "store/hooks/useRisk";
import {
  EMAIL,
  RESET,
  SECTION_1,
  SECTION_2,
  SECTION_3,
  STATUS_SUCCESS_200,
  TOKEN,
} from "../../../constants/constants";
import { useUser } from "store/hooks/useUser";

export const OTPVerification = React.memo(() => {
  const navigate = useNavigate();

  const { setQuestion } = useQuestions();

  const { setCustomerId } = useUser();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [successMessage, setSuccessMessage] = useState<string>("");

  const [otp, setOtp] = useState<string>("");

  const [email] = useSessionStorage<string>(EMAIL);

  const { setInitialRisk } = useRisk();

  const getUserInformation = useCallback(async () => {
    const userInfo = await getUserDetails();

    if (userInfo.status === STATUS_SUCCESS_200) {
      if (userInfo.data.riskProfile) {
        setInitialRisk(userInfo.data.riskProfile);
      }
      if (userInfo.data.reset) {
        sessionStorage.setItem(RESET, JSON.stringify(userInfo.data.reset));
      }

      if (userInfo.data.customerPortfolioId) {
        navigate("/dashboard");
      } else if (
        !userInfo.data.reset &&
        userInfo.data.anonymousCustomerId &&
        !userInfo.data.customerPortfolioId
      ) {
        setCustomerId(userInfo.data.anonymousCustomerId);
        setQuestion(3002, SECTION_3);
        navigate("/profiling");
      } else if (
        userInfo.data.reset &&
        !userInfo.data.anonymousCustomerId &&
        !userInfo.data.customerPortfolioId
      ) {
        setQuestion(1001, SECTION_1);
        navigate("/profiling");
      } else if (userInfo.data.reset && userInfo.data.anonymousCustomerId) {
        setCustomerId(userInfo.data.anonymousCustomerId);
        if (userInfo.data.riskProfile) {
          setQuestion(3001, SECTION_3);
          navigate("/profiling");
        } else {
          setQuestion(2001, SECTION_2);
          navigate("/profiling");
        }
      }
    } else {
      setErrorMessage(userInfo.description);
    }
  }, [navigate, setCustomerId, setInitialRisk, setQuestion]);

  const handleOtpVerification = useCallback(async () => {
    if (email) {
      const response = await loginUser({ email, otp: otp });

      if (response.status === STATUS_SUCCESS_200) {
        sessionStorage.setItem(TOKEN, response.data.accessToken);
        getUserInformation();
      } else {
        setErrorMessage(response.description);
      }
    }
  }, [email, getUserInformation, otp]);

  const handleResendOtp = useCallback(async () => {
    setOtp("");
    if (email) {
      const response = await generateOtp({ email: email });
      if (response.status === STATUS_SUCCESS_200) {
        setSuccessMessage("OTP sent successfully");
      } else {
        setErrorMessage(response.description);
      }
    }
  }, [email]);

  const handleOtpChange = useCallback((code: string) => {
    setOtp(code);
  }, []);

  return (
    <>
      <RegistrationHeader>
        <div className="sign_right_block sign_right_otp">
          <div className="sign_right_head">
            <h1>OTP Verification</h1>
            <p>
              Your SignUp/SignIn is secured with OTP sent on your registered
              email address
            </p>
          </div>
          <div className="card sign_right_card">
            <div className="sign_right_form otp_main">
              <h4>Enter the OTP sent to your email address</h4>
              <div className="otp_section">
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={5}
                  inputStyle={{
                    width: "48px",
                    height: "58px",
                    fontSize: "16px",
                    color: "#543979",
                    borderRadius: 6,
                    border: "1px solid #D0D0D0",
                    background: "transparent",
                  }}
                  isInputNum
                  className="otp_block"
                />
              </div>
              {errorMessage && (
                <p className="error text-capitalize">{errorMessage}</p>
              )}
              <div className="form_btn otp_btn">
                <button
                  className="btn primary_btn"
                  type="button"
                  onClick={handleOtpVerification}
                  disabled={otp.split("").length !== 5}>
                  Verify
                </button>
              </div>
              <div className="resend_otp">
                <button className="btn btn-link" onClick={handleResendOtp}>
                  Resend OTP
                </button>
              </div>
              {successMessage && (
                <p className="text-success text-capitalize">{successMessage}</p>
              )}
              <p className="already_acc">
                Already having an account with WealthZen?
                <a href="/login">&nbsp;Login</a>
              </p>
            </div>
          </div>
        </div>
      </RegistrationHeader>
    </>
  );
});
