import React from "react";
import { useAppContext } from "../store/context";
import { useAppAction } from "../store/hooks/useAppActions";

const SideNav = () => {
  const [state] = useAppContext();
  const { toggleSideBar } = useAppAction();
  const { sideBar } = state;
  return (
    <div className={`side_nav_section  ${sideBar ? "" : "d-none"}`}>
      <div className="side_nav_block">
        <a href="#" className="close_nav" onClick={toggleSideBar}>
          <img src="./images/icons/close.svg" />
        </a>
        <div className="side_head_sec">
          <h4>
            Weathzen arrives at these portfolios through a rigorous 3 step
            process
          </h4>
          <ul>
            <li>Identifying best performing, low cost ETFs</li>
            <li>Translating macro view into…</li>
            <li>Using macro variables…</li>
          </ul>
        </div>

        <div className="side_body_content">
          <div className="speed_block d-flex">
            <img src="./images/icons/speed-icon.svg" />
            <h4>Medium</h4>
            <p>Risk Preference</p>
          </div>
          <div className="speed_status_sec">
            <ul>
              <li>
                <div className="sidebar_risk_head">
                  <img src="./images/icons/portfolio-com.svg" />
                  <h4>Portfolio composition</h4>
                </div>
                <div>
                  <p>50% fixed income, 30% equities</p>
                </div>
              </li>
              <li>
                <div className="sidebar_risk_head">
                  <img src="./images/icons/sharp-ratio.svg" />
                  <h4>Sharpe ratio</h4>
                </div>
                <div>
                  <p>2.31%</p>
                </div>
              </li>
              <li>
                <div className="sidebar_risk_head">
                  <img src="./images/icons/portfolio-com.svg" />
                  <h4>200 day volatility</h4>
                </div>
                <div>
                  <p>17%</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="like_btn_block">
            <p>Was this helpful? Please answer to close the pop up!</p>
            <button className="btn">
              Yes <img src="./images/icons/up-thumb.svg" />
            </button>
            <button className="btn">
              No <img src="./images/icons/down-thumb.svg" />
            </button>
          </div>
          <div className="m-2">
            <p>Your responses enable us to improve your experience</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SideNav);
