import { WZ_BACKEND } from "../constants/env-constants";

export const getAllCustomerData = async () => {
  try {
    const res = await WZ_BACKEND().get("/v1/api/invest/getAll");
    return res;
  } catch (err) {
    return err.response.data;
  }
};

export const fileUpload = async (file: FormData, option: any) => {
  try {
    const res = await WZ_BACKEND().post("/v1/api/invest/upload", file, option);
    return res;
  } catch (err) {
    return err.response.data;
  }
};

export const getAllModalPortfolios = async () => {
  try {
    const res = await WZ_BACKEND().get("/v1/api/model-portfolio/all");
    return res;
  } catch (err) {
    return err.response.data;
  }
};

export const sendVersionNotification = async (id: string) => {
  try {
    const res = await WZ_BACKEND().post(
      `/v1/api/customer-portfolio/trigger/version/${id}`
    );
    return res;
  } catch (err) {
    return err.response.data.error;
  }
};
