import React, { useCallback, useEffect, useState } from "react";
import { AdminHeader } from "components/Admin/Header/AdminHeader";
import { CustomerPortfolio } from "../../../typings/PortfolioPage/types/types";
import { useLocation } from "react-router-dom";
import { STATUS_SUCCESS_200 } from "constants/constants";
import { getCustomerPortfolioDetails } from "apis/userApi";

export const PortfolioDetails = React.memo(() => {
  const location = useLocation();

  const customerPortfolioId = location.pathname.split("/")[5];

  const [customerPortfolio, setCustomerPortfolio] =
    useState<CustomerPortfolio>();

  const portfolioDetails = useCallback(async () => {
    const response = await getCustomerPortfolioDetails(customerPortfolioId);
    if (response.status === STATUS_SUCCESS_200) {
      setCustomerPortfolio(response.data);
    }
  }, [customerPortfolioId]);

  useEffect(() => {
    portfolioDetails();
  }, [portfolioDetails]);

  return (
    <div>
      <AdminHeader />
      {customerPortfolio ? (
        <div>
          <div className="card shadow m-3">
            <div>
              <div className="text-center m-3">
                <h2 className="m-2 ">Portfolio Details</h2>
              </div>
              <div className="m-5">
                <div>
                  <b>User ID</b>: {customerPortfolio.userId}
                </div>
                <div className="text-capitalize">
                  <b>User Name</b>:{customerPortfolio.userName}
                </div>

                <div>
                  <b>Customer Portfolio ID</b>:
                  {customerPortfolio.customerPortfolioId}
                </div>
                <div>
                  <b>Portfolio Version</b>:{customerPortfolio.currentVersion}
                </div>
              </div>
            </div>
            <div className="card m-3 shadow">
              <div className="text-center m-3">
                <h3>Portfolio Stats</h3>
              </div>
              <div className="d-flex overflow-auto m-3 ">
                <table className="table table-bordered">
                  <thead>
                    <tr className="bg-info">
                      <th scope="col">As Of Date</th>
                      <th scope="col">Current Value</th>
                      <th scope="col">Invested Value</th>
                      <th scope="col">XIRR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerPortfolio.aggregatedPortfolioStatsByAsOfDate?.map(
                      (portfolioStats) => {
                        return (
                          <>
                            {portfolioStats.map((portfolioStat) => {
                              return (
                                <tr key={portfolioStat.id}>
                                  <td>{portfolioStat.asOfDate}</td>
                                  <td>{portfolioStat.currentValue}</td>
                                  <td>{portfolioStat.investedValue}</td>
                                  <td>{portfolioStat.xirr}</td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card  shadow m-3 mt-5">
              <div className="text-center m-3">
                <h3>Security Holdings</h3>
              </div>
              <div className=" d-flex overflow-auto m-3 ">
                <table className="table table-bordered">
                  <thead className="bg-info">
                    <tr>
                      <th scope="col">As Of Date</th>
                      <th scope="col">ISIN / Ticker</th>
                      <th scope="col">Security Name</th>
                      <th scope="col">Holding Percentage</th>
                      <th scope="col">Avg Purchase Price</th>
                      <th scope="col">Units</th>
                      <th scope="col">Invested Value</th>
                      <th scope="col">Current Value</th>
                      <th scope="col">UGL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerPortfolio.customerSecurityHoldingsByAsOfDate?.map(
                      (customerSecurityHoldings) => {
                        return (
                          <>
                            {customerSecurityHoldings.map(
                              (customerSecurityHolding) => {
                                return (
                                  <tr key={customerSecurityHolding.id}>
                                    <td>{customerSecurityHolding.asOfDate}</td>
                                    <td>{customerSecurityHolding.isin}</td>
                                    <td>
                                      {customerSecurityHolding.securityName}
                                    </td>
                                    <td>
                                      {
                                        customerSecurityHolding.currentHoldingPercentage
                                      }
                                    </td>
                                    <td>
                                      {customerSecurityHolding.avgPurchasePrice}
                                    </td>
                                    <td>{customerSecurityHolding.units}</td>
                                    <td>
                                      {customerSecurityHolding.investedValue}
                                    </td>
                                    <td>
                                      {customerSecurityHolding.currentValue}
                                    </td>
                                    <td>{customerSecurityHolding.ugl}</td>
                                  </tr>
                                );
                              }
                            )}
                            <tr>
                              <hr></hr>
                            </tr>
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card  shadow m-3 mt-5">
              <div className="text-center m-3">
                <h3>Benchmark</h3>
              </div>
              <div className=" d-flex overflow-auto m-3 ">
                <table className="table table-bordered">
                  <thead className="bg-info">
                    <tr>
                      <th scope="col">As Of Date</th>
                      <th scope="col">Identifier</th>
                      <th scope="col">Name</th>

                      <th scope="col">Invested Value</th>
                      <th scope="col">Current Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerPortfolio.benchMarkByAsOfDate?.map(
                      (benchmarks) => {
                        return (
                          <>
                            {benchmarks.map((benchmark) => {
                              return (
                                <tr key={benchmark.id}>
                                  <td>{benchmark.asOfDate}</td>
                                  <td>{benchmark.benchmarkIdentifier}</td>
                                  <td>{benchmark.benchmarkName}</td>
                                  <td>{benchmark.benchmarkInvestedValue}</td>
                                  <td>{benchmark.benchmarkCurrentValue}</td>
                                </tr>
                              );
                            })}
                            <tr>
                              <hr></hr>
                            </tr>
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="m-5 text-center error fs-5">No data available</div>
      )}
    </div>
  );
});
