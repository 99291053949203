import {
  ACUTE_KNOWLEDGE,
  HIGH,
  I_AM_NOT_SURE,
  LIMITED_KNOWLEDGE,
  LOW,
  MEDIUM,
  NO,
  YES,
} from "../constants/constants";
import ChartData from "../components/ChartData";
import { QuestionSet } from "../typings/types";

const questions: QuestionSet = {
  meta: [
    {
      currentId: 1001,
      currentSection: "section1",
      points: 0,
    },
  ],
  section1: [
    {
      id: 1001,
      multiple: false,
      isRequired: true,
      questionNo: "Q1",
      title: "Risk Appetite",
      name: "The broad level of risk that you, as an investor, would prefer to take when investing your money.",
      question: "What is your investment risk appetite?",
      img: "/images/qus-img/qus-img1.png",
      questionTypeId: 1,
      quizType: "B",
      done: false,
      answer: null,
      value: null,
      options: [
        {
          name: HIGH.toUpperCase(),
          answer: HIGH,
          value: HIGH,

          selected: false,
        },
        {
          name: MEDIUM.toUpperCase(),
          answer: MEDIUM,
          value: MEDIUM,

          selected: false,
        },

        {
          name: LOW.toUpperCase(),
          answer: LOW,
          value: LOW,
          selected: false,
        },
      ],
    },
    {
      id: 1002,
      isRequired: true,
      multiple: false,
      questionNo: "Q2",
      title: "Outlook for Global Macro Growth",
      name: "Economic Growth is a fundamental driver of financial markets and an indicator of the macroeconomic expansion across different sectors",
      question: "What is your view on Economic Growth in the Global markets?",
      img: "/images/qus-img/qus-img2.png",
      questionTypeId: 2,
      quizType: "B",
      done: false,
      answer: null,
      value: null,
      options: [
        {
          name: HIGH.toUpperCase(),
          answer: HIGH,
          value: 1,
          selected: false,
        },
        {
          name: LOW.toUpperCase(),
          answer: LOW,
          value: 0,
          selected: false,
        },
      ],
    },
    {
      id: 1003,
      isRequired: true,
      multiple: false,
      questionNo: "Q3",
      title: "Inflation",
      name: "Inflation in the global markets refers to the persistent increase in the overall prices of goods and services, leading to a decline in the purchasing power of money, impacting economies, consumers, and investors worldwide.",
      question: "What is your view on Inflation in the Global market?",
      img: "/images/qus-img/qus-img3.png",
      questionTypeId: 3,
      quizType: "B",
      answer: null,
      value: null,
      done: false,
      options: [
        {
          name: HIGH.toUpperCase(),
          answer: HIGH,
          value: 1,
          selected: false,
        },
        {
          name: LOW.toUpperCase(),
          answer: LOW,
          value: 0,
          selected: false,
        },
      ],
    },
    {
      id: 1004,
      isRequired: true,
      multiple: false,
      questionNo: "Q4",
      title: "Risk-On or Risk-Off",
      name: "Based on an investor's optimism or pessimism toward earnings growth and economic factors, you can take a risk-on or bullish view or a risk-off, bearish view of markets.",
      question: "What is your view of the market?",
      img: "/images/qus-img/qus-img4.png",
      questionTypeId: 4,
      quizType: "B",
      done: false,
      answer: null,
      value: null,
      options: [
        {
          name: "Optimistic or Risk-on",
          answer: HIGH,
          value: 1,
          selected: false,
        },
        {
          name: "Pessimistic or Risk-off",
          answer: LOW,
          value: 0,
          selected: false,
        },
      ],
    },
    // {
    //   id: 1005,
    //   isRequired: true,  multiple: false,
    //   isSectionEnd: false,
    //   questionNo: "Q5",
    //   title: "title required",
    //   name: "Investors can adopt different investment themes, such as value investing, growth investing, technology, healthcare, regions, etc. These themes entail specific approaches to selecting ETFs, aligning with individual risk tolerances and financial aims.",
    //   question: "What are you preferred themes?",
    //   img: "/images/qus-img/qus-img5.png",
    //   questionTypeId: 5,
    //   quizType: "B",
    //   done: false,
    //   answer: null,
    //   value: null,
    //   options: [
    //     {
    //       name: "Up",
    //       value: "1",
    //       answer: "A",
    //       selected: false,
    //     },
    //     {
    //       name: "Down",
    //       value: "0",
    //       answer: "B",
    //       selected: false,
    //     },
    //   ],
    // },
    {
      id: 1005,
      isRequired: true,
      multiple: true,
      isSectionEnd: true,
      questionNo: "Q5",
      title: "Optimization",
      name: "There can be different goals when constructing a portfolio. Investors can choose to prioritize the highest returns or lowest risk or a risk-adjusted returns approach using the Sharpe Ratio",
      question:
        "What optimization method would you like to choose? (Select one or more)",
      img: "/images/rest-img.png",
      questionTypeId: 5,
      quizType: "B",
      done: false,
      answer: null,
      value: null,
      options: [
        {
          name: "Maximise Returns",
          value: "annualised_return",
          answer: "A",
          selected: false,
        },
        {
          name: "Minimise Volatility",
          value: "volatility",
          answer: "B",
          selected: false,
        },
        {
          name: "Optimise Sharpe Ratio",
          value: "sharpe_ratio",
          answer: "C",
          selected: false,
        },
      ],
      next: {
        section: "section2",
        id: 2001,
      },
    },
    // {
    //   id: 1006,
    //   name: "",
    //   questionTypeId: 5,
    //   multiple: false,
    //   isRequired: false,
    //   done: false,
    //   type: "popup",
    //   options: [],
    //   next: {
    //     section: "section2",
    //     id: 2001,
    //   },
    // },
  ],
  // section2: [
  //   // {
  //   //   id: 2001,
  //   //   isRequired: true,
  //   //   questionNo: "Q1",
  //   //   name: "Which age group do you belong to?",
  //   //   img: "/images/qus-img/qus-img6.png",
  //   //   questionTypeId: 6,
  //   //   quizType: "A",
  //   //   done: false,
  //   //   answer: null,
  //   //   value: null,
  //   //   options: [
  //   //     {
  //   //       name: "Less than 35",
  //   //       value: "",
  //   //       answer: "A",
  //   //       selected: false,
  //   //     },
  //   //     {
  //   //       name: "More than 35",
  //   //       value: "",
  //   //       answer: "B",
  //   //       selected: false,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   id: 2002,
  //   //   isRequired: true,
  //   //   questionNo: "Q2",
  //   //   name: "How much is $50,000 as a percentage of your total wealth?",
  //   //   img: "/images/qus-img/qus-img7.png",
  //   //   questionTypeId: 7,
  //   //   quizType: "A",
  //   //   done: false,
  //   //   answer: null,
  //   //   value: null,
  //   //   options: [
  //   //     {
  //   //       name: "<25%",
  //   //       value: "",
  //   //       answer: "A",
  //   //       selected: false,
  //   //     },
  //   //     {
  //   //       name: "25-75%",
  //   //       value: "",
  //   //       answer: "B",
  //   //       selected: false,
  //   //     },
  //   //     {
  //   //       name: ">75%",
  //   //       value: "",
  //   //       answer: "C",
  //   //       selected: false,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   id: 2003,
  //   //   isRequired: true,
  //   //   questionNo: "Q3",
  //   //   name: "What is your investment time horizon?",
  //   //   img: "/images/qus-img/qus-img8.png",
  //   //   questionTypeId: 8,
  //   //   quizType: "A",
  //   //   done: false,
  //   //   answer: null,
  //   //   value: null,
  //   //   options: [
  //   //     {
  //   //       name: "Less than 1 year",
  //   //       value: "",
  //   //       answer: "A",
  //   //       selected: false,
  //   //     },
  //   //     {
  //   //       name: "Between 1 to 3 years",
  //   //       value: "",
  //   //       answer: "B",
  //   //       selected: false,
  //   //     },
  //   //     {
  //   //       name: "Greater than 3 years",
  //   //       value: "",
  //   //       answer: "C",
  //   //       selected: false,
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     id: 2004,
  //     isRequired: true,
  //     isSectionEnd: true,
  //     questionNo: "Q4",
  //     name: "If your portfolio is down 15%, what will you do?",
  //     img: "/images/qus-img/qus-img9.png",
  //     questionTypeId: 9,
  //     quizType: "A",
  //     done: false,
  //     answer: null,
  //     value: null,
  //     options: [
  //       {
  //         name: "Rebalance or liquidate at once",
  //         value: "LOW",
  //         answer: "A",
  //         selected: false,
  //       },
  //       {
  //         name: "Rebalance or liquidate within the next 6 months",
  //         value: "MEDIUM",
  //         answer: "B",
  //         selected: false,
  //       },
  //       {
  //         name: "Sleep peacefully since you’re in it for the long haul",
  //         value: "HIGH",
  //         answer: "C",
  //         selected: false,
  //       },
  //     ],
  //     next: {
  //       section: "section3",
  //       id: 3001,
  //     },
  //   },
  // ],
  section2: [
    {
      type: "display",
      id: 2001,
      isRequired: false,
      name: "",
      questionTypeId: 11,
      done: false,
      render: <ChartData />,
      options: [],
      customNext: true,
    },
    // {
    //   id: 2002,
    //   name: "",
    //   isRequired: false,
    //   hideNext: true,
    //   questionTypeId: 12,
    //   done: false,
    //   render: <ChartData />,
    //   options: [],
    //   customNext: true,
    //   next: {
    //     section: "section4",
    //     id: 4001,
    //   },
    // },
  ],
  // section3: [
  //   {
  //     id: 4001,
  //     name: "Congratulations! You just made you first investment!",
  //     img: "/images/qus-img/finished.png",
  //     questionTypeId: 1,
  //     isRequired: false,
  //     done: false,
  //     answer: null,
  //     value: null,
  //     options: [],
  //     end: true,
  //     redirect: "/dashboard",
  //   },
  // ],
};

export default questions;
