import React, { useEffect } from "react";
import { AppContextProvider } from "./store/context";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-circular-progressbar/dist/styles.css";
import RoutesPath from "./routes/Routes";

const App = () => {
  return (
    <div className="App">
      <AppContextProvider>
        <RoutesPath />
      </AppContextProvider>
    </div>
  );
};

export default App;
