import React, { useCallback } from "react";
import { postWidgetAnswer } from "../../apis/questionnaireResponseApi";
import {
  FINANCIAL_KNOWLEDGE,
  INVESTMENT_PORTFOLIO_ETF_WIDGET_ID,
  VOTE_NO,
  VOTE_YES,
  YES,
} from "../../constants/constants";
import { useSessionStorage } from "../../store/hooks/useSessionStorage";

type EtfWidgetProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  knowledge: string;
};

export const EtfWidget = React.memo<EtfWidgetProps>(
  ({ setModal, knowledge }) => {
    const [financialKnowledge] = useSessionStorage<string>(FINANCIAL_KNOWLEDGE);

    const handleAnswer = useCallback((answer: string) => {
      answerHandler(answer);
    }, []);

    const answerHandler = useCallback(async (userResponse: string) => {
      setModal((modal: boolean) => !modal);
      await postWidgetAnswer(INVESTMENT_PORTFOLIO_ETF_WIDGET_ID, userResponse);
    }, []);

    return (
      <>
        <div className="widget_popup_section">
          <div className="row ">
            <div className="widget_logo">
              <img src="./images/logo.png" alt="" />
            </div>
            <button
              type="button"
              className="btn-close flex-reverse"
              onClick={() => {
                setModal(false);
              }}
              aria-label="Close"></button>
          </div>
          <ul>
            {financialKnowledge === YES ? (
              <>
                <li>
                  We pick the best ETFs based on 3 factors - performance, size
                  (AUM) and expenses
                </li>
                <li>
                  Standard ETF basket gives a balanced weightage to the 3
                  factors
                </li>
                <li>
                  Performance ETF basket gives higher weightage to performance
                </li>
              </>
            ) : (
              <>
                <li>
                  We pick the best ETFs based on 3 factors - performance, size
                  (AUM) and expenses
                </li>
                <li>
                  Standard ETF basket gives a balanced weightage to the 3
                  factors
                </li>
                <li>
                  Performance ETF basket gives higher weightage to performance
                </li>
              </>
            )}
          </ul>
          <div className="like_btn_block">
            <p>Was this helpful? Please answer to close the pop up!</p>
            <button
              data-bs-dismiss="modal"
              type="button"
              className="btn widget_btn"
              onClick={() => handleAnswer(VOTE_YES)}>
              Yes <img src="./images/icons/up-thumb.svg" alt="thumb_up" />
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              className="btn widget_btn"
              onClick={() => handleAnswer(VOTE_NO)}>
              No <img src="./images/icons/down-thumb.svg" alt="thumb_down" />
            </button>
          </div>
          <div className="mt-3 popup_bottom_msg">
            <p>Your responses enable us to improve your experience</p>
          </div>
        </div>
      </>
    );
  }
);
