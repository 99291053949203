import React from "react";
import ChartData from "../../components/ChartData";
import { useQuestions } from "../../store/hooks/useQuestion";
import { Option } from "../../typings/types";

const QuestionBlock = () => {
  const { getQuestion, saveAnswer } = useQuestions();
  const currentQuestion = getQuestion();

  const changeHandler = (element: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = element.target;

    const newQuesObj = { ...currentQuestion };

    if (newQuesObj.multiple) {
      let flag = false;
      newQuesObj.options = newQuesObj.options.map((element: Option) => {
        if (element.name === value) {
          return {
            ...element,
            selected: !element.selected,
          };
        }
        return {
          ...element,
          selected: element.selected,
        };
      });

      const selectedOption = newQuesObj.options.filter(
        (element: Option) => element.selected
      );

      newQuesObj.answer = selectedOption[0].answer;
      newQuesObj.value = selectedOption.map(
        (option: { value: any }) => option.value
      );
      if (newQuesObj.value.length > 0) {
        newQuesObj.done = true;
      }
    } else {
      newQuesObj.done = true;

      newQuesObj.options = newQuesObj.options.map((element: Option) => {
        if (element.name === value) {
          return {
            ...element,
            selected: true,
          };
        }
        return {
          ...element,
          selected: false,
        };
      });

      const selectedOption = newQuesObj.options.filter(
        (element: Option) => element.selected
      );

      newQuesObj.answer = selectedOption[0].answer;
      newQuesObj.value = selectedOption[0].value;
    }
    saveAnswer(newQuesObj);
  };
  if (currentQuestion?.id !== 2005) {
    return (
      <div className="container mb-4">
        <div className="quiz_pick_section">
          <div className="quiz_img">
            <div className="img_placeholder">
              <img src={currentQuestion?.img} />
            </div>
          </div>
        </div>
        <div className="quiz_pick_block">
          <div className="question_content ">
            <h4 className="title_color">{currentQuestion?.title}</h4>
          </div>
          <div className="question_content mb-3">
            <h6>{currentQuestion?.name}</h6>
          </div>
          <div className="question_content mb-3">
            <h5>{currentQuestion?.question}</h5>
          </div>
          <div className="d-flex pick_section mt-3 ">
            {currentQuestion?.options.map((element: Option) => {
              return (
                <div key={currentQuestion.options.name}>
                  <input
                    className="checkbox-booking"
                    style={{ visibility: "hidden" }}
                    type={currentQuestion.multiple ? "checkbox" : "radio"}
                    name={`${currentQuestion.id}`}
                    id={`${currentQuestion.id}_${element.name}`}
                    value={element.name}
                    onChange={changeHandler}
                  />
                  <label
                    className="for-checkbox-booking label_width2"
                    htmlFor={`${currentQuestion.id}_${element.name}`}>
                    <span className="text">{element.name}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return <ChartData />;
  }
};

export default React.memo(QuestionBlock);
