import React, { useEffect, useRef, useState } from "react";
import { LineChart } from "./Charts/LineCharts";
import { LineData } from "../typings/Charts/types/types";

type PortfolioLineChart = {
  graphData: LineData[];
};

export const PortfolioLineGraph = React.memo<PortfolioLineChart>(
  ({ graphData }) => {
    const [lineChartData, setLineChartData] = useState<LineData[] | []>([]);

    useEffect(() => {
      if (graphData.length > 2) {
        const lineChartFirstValue = graphData[0];
        const lineChartLastValue = graphData.slice(-1)[0];

        const lineChartMiddleValues = graphData.slice(1, -1);
        const lineChartMiddleValuesDivide = lineChartMiddleValues.filter(
          (linePoint, index) =>
            index % Math.floor(Math.abs(lineChartMiddleValues.length / 8)) === 0
        );

        const lineChartMiddleEightValues = lineChartMiddleValuesDivide.slice(
          0,
          8
        );

        const lineChartDisplayValues = [
          lineChartFirstValue,
          ...lineChartMiddleEightValues,
          lineChartLastValue,
        ];

        setLineChartData(lineChartDisplayValues);
      }
    }, [graphData]);

    return lineChartData.length > 2 ? (
      <div className="portfolio_line_graph_section mt-4">
        <div className="primary_head mb-5">
          <h4>Portfolio Performance</h4>
          <p>
            Below is your portfolio's historical performance; If you had
            invested $10,000 in 2017 this is how your portfolio would have
            evolved
          </p>
        </div>
        <div className="portfolio_line_graph_block">
          <div className="line__chart_block">
            <LineChart lineData={lineChartData} />
          </div>
        </div>
      </div>
    ) : null;
  }
);
