import { ResponsivePie } from "@nivo/pie";
import { PieData } from "../../typings/Charts/types/types";
import React from "react";
import { useRisk } from "../../store/hooks/useRisk";
import { ColorMap, Holdings } from "../../typings/types";
import { LOW, MEDIUM } from "constants/constants";

type PieChartProps = {
  holdings: Holdings;
};

export const PieChart = React.memo<PieChartProps>(({ holdings }) => {
  const colors: ColorMap = {
    Cash: "#E8B23E",
    Commodities: "#D95E73",
    EquityDM: "#5b5f93",
    EquityEM: "#9194c4",
    FixedIncomeCorp: "#646CCE",
    EquityTheme: "#dbddff",
    FixedIncomeGovt: "#A355C9",
    FixedIncomeTheme: "#A234af",
  };

  const eachHoldings = holdings.map((holdingItem, index) => {
    return {
      id: holdingItem["factor.asset_class"],
      label: holdingItem["factor.asset_class"].slice(0, 6),
      value: holdingItem.weight,
      color:
        colors[holdingItem["factor.asset_class"].replace(/[^A-Z0-9]/gi, "")],
    };
  });

  return (
    <div className="pie_chart_section p-2">
      <ResponsivePie
        data={eachHoldings}
        margin={{ top: 10, right: 90, bottom: 10, left: 90 }}
        innerRadius={0}
        activeOuterRadiusOffset={8}
        colors={({ data }) => data[`color`]}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabel={(element) => {
          return element.label + " (" + element.value + "%)";
        }}
        arcLinkLabelsSkipAngle={3}
        arcLinkLabelsTextOffset={0}
        arcLinkLabelsTextColor={{ theme: "labels.text.fill" }}
        arcLinkLabelsDiagonalLength={8}
        arcLinkLabelsStraightLength={0}
        arcLinkLabelsThickness={0}
        arcLinkLabelsColor={{ from: "color", modifiers: [] }}
        enableArcLabels={false}
        arcLabel="value"
        arcLabelsSkipAngle={5}
        arcLabelsTextColor={{ theme: "background" }}
        legends={[]}
      />
      {/*
      <div className="pie_chart_text">
        <div
          className={`slide_nav_section chart_risk ${
            currentRisk === LOW
              ? "low_slide"
              : currentRisk === MEDIUM
              ? "medium_slide"
              : "high_slide"
          }`}>
          <div className="slide_circle_indicate">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <h4>{currentRisk}</h4>
        </div>
        <p>Need a text </p>
      </div> */}
    </div>
  );
});
