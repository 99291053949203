import React from "react";
import { FieldInputProps, FieldProps } from "formik";

type TextFieldProps = FieldProps & {
  type?: string;
  label?: string;
  id: string;
  field?: FieldInputProps<any>;
  value: string | number;
};

export const TextField: React.FC<TextFieldProps> = React.memo(
  ({
    field,
    type = "text",
    label,
    id,
    value,
    form: { touched, errors },
    ...props
  }) => {
    return (
      <div>
        <input
          id={id}
          type={type}
          {...field}
          {...props}
          className={`form-control ${
            touched && errors[field.name] && "is-invalid"
          }`}
          placeholder={label}
        />
        {touched[field.name] && errors[field.name] && (
          <div className="error">{errors[field.name] as string}</div>
        )}
      </div>
    );
  }
);
