import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { DEFAULT_PERFORMANCE, OPTED_NO } from "../constants/constants";
import { AppState } from "../typings/types";
import questions from "./questionsData";

export const AppContext = createContext<
  [AppState, Dispatch<SetStateAction<AppState>>] | undefined
>(undefined);

export const AppContextProvider = ({ children }: { children: JSX.Element }) => {
  const dataString: string | null = sessionStorage.getItem("appState")
    ? sessionStorage.getItem("appState")
    : null;
  const data = dataString ? JSON.parse(dataString) : null;
  const [state, setState] = useState<AppState>({
    user: data ? data.user : null,
    questions: data ? data.questions : questions,
    progress: data?.progress || false,
    headerTxt: data?.headerTxt || false,
    quizSec: data?.quizSec || false,
    sideBar: false,
    start: data?.start || false,
    riskProfile: data?.riskProfile || null,
    initialRiskProfile: data?.initialRiskProfile || null,
    performance: DEFAULT_PERFORMANCE,
    theme: OPTED_NO,
    portfolioId: data?.portfolioId || null,
    loading: false,
    customerId: data?.customerId || null,
    disclaimer: data?.disclaimer ?? true,
    portfolioVersion: data?.portfolioVersion || null,
  });
  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const state = useContext(AppContext);
  if (state === undefined) {
    throw Error(`useIsLoading must be inside its provider`);
  }
  return state;
};
