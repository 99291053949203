import { useState, useEffect } from "react";

type ReturnType<T> = [
  string | null,
  React.Dispatch<React.SetStateAction<string | null>>
];

export const useSessionStorage = <T>(
  key: string,
  initialValue?: T
): ReturnType<T> => {
  const [item, setItem] = useState<string | null>(() => {
    if (!key) {
      return;
    }

    try {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (err) {
      return initialValue;
    }
  });
  useEffect(() => {
    if (item) {
      try {
        sessionStorage.setItem(key, JSON.stringify(item));
      } catch (err) {
        console.error(err);
      }
    }
  }, [item, key]);

  return [item, setItem];
};
