export const DEFAULT_THEME = "NO";
export const DEFAULT_PERFORMANCE = "Balanced";
export const YES = "YES";
export const NO = "NO";
export const FINANCIAL_KNOWLEDGE = "financialKnowledge";
export const I_AM_NOT_SURE = "I'm not sure. Test me!";
export const CUSTOMER_ID = "customerId";
export const RISK_PROFILE = "riskProfile";
export const LOW = "low";
export const MEDIUM = "medium";
export const HIGH = "high";
export const ACUTE_KNOWLEDGE = "Acute knowledge";
export const LIMITED_KNOWLEDGE = "Limited knowledge";
export const SUCCESS = "success";
export const IS_LOGGED_IN = "isLoggedIn";
export const TOKEN = "token";
export const BALANCED = "Balanced";
export const WEALTHZEN_PORTFOLIO = "WealthZen Portfolio";
export const BENCHMARK = "Benchmark";
export const INVESTMENT_PORTFOLIO_Q_ID = 3002;
export const OPTED_NO = "No";
export const OPTED_YES = "Yes";
export const OPTED_THEME1 = "theme1";
export const THEME = "theme";
export const PERFORMANCE = "Performance";
export const POPUP = "popup";
export const INVEST = "invest";
export const ERROR = "Error";
export const STATUS_SUCCESS_200 = 200;
export const STATUS_SUCCESS_201 = 201;
export const STATUS_FAILURE_400 = 400;
export const EMAIL = "email";
export const EQUITY_DM = "Equity - DM";
export const EQUITY_EM = "Equity - EM";
export const EQUITY_THEME = "Equity Theme";
export const COMMODITIES = "Commodities";
export const CASH = "Cash";
export const FIXED_INCOME_CORP = "FI - Corp";
export const FIXED_INCOME_GOVT = "FI - Govt";
export const FIXED_INCOME_THEME = "Fixed Income Theme";
export const REIT = "REIT";
export const INVESTMENT_PORTFOLIO_BEFORE_LOGIN_Q_ID = 3001;
export const USER_FLOW_FIRST_QUESTION = 1001;
export const INVESTMENT_PORTFOLIO_THEME_WIDGET_ID = 2;
export const INVESTMENT_PORTFOLIO_ETF_WIDGET_ID = 3;
export const INVESTMENT_PORTFOLIO_CONSTRUCTION = 1;
export const SLIDER_LOW = "LOW";
export const SLIDER_MEDIUM = "MEDIUM";
export const SLIDER_HIGH = "HIGH";
export const RESET = "reset";
export const VOTE_YES = "yes";
export const VOTE_NO = "no";

// Dashboard Const
export const BITCOIN = "Bitcoin";
export const FIXED_DEPOSIT = "Fixed Deposit";
export const THEME_KNOWLEDGE = "Theme";
export const ETF_KNOWLEDGE = "ETF";

export const SECTION_1 = "section1";
export const SECTION_2 = "section2";
export const SECTION_3 = "section3";
export const SECTION_4 = "section4";
export const QUESTION_ID_1001 = 1001;
export const QUESTION_ID_2001 = 2001;

//social authentication
export const LOGIN = "login";
export const FACEBOOK = "facebook";
export const GOOGLE = "google";

//Portfolio Status
export const FUNDED = "Funded";
export const INVESTED = "Invested";
export const REBALANCED = "Rebalanced";

export const OPTIMIZED_DATA = "optimizedData";
export const OBJECTIVE_FUNCTION = "objectiveFunctions";
export const CURRENT_ALLOCATION = "currentAllocations";
