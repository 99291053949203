import React from "react";

type WidgetProps = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
};

export const Widget = React.memo<WidgetProps>(
  ({ modal, setModal, children }) => {
    return (
      <>
        {modal ? (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-body widget_popup_body">{children}</div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
);
