import React from "react";

type UpdateDateProps = {
  date: string;
};

export const UpdatedDate = React.memo<UpdateDateProps>(({ date }) => {
  const getDayDiff = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;

    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  };

  const startDate: Date = new Date(date);
  const endDate: Date = new Date();
  const msInDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(
    Math.abs(Number(endDate) - Number(startDate)) / msInDay
  );

  const msInHr = 60 * 60 * 1000;
  const diffHours = Math.round(
    Math.abs(Number(endDate) - Number(startDate)) / msInHr
  );

  return (
    <>
      <div className="update_txt">
        <p>
          Updated {diffDays <= 1 ? diffHours + " hours" : diffDays + " days"}
          {" ago "}
        </p>
      </div>
    </>
  );
});
