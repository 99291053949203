import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { generateOtp, registerUser } from "../../../apis/userApi";
import { TextField } from "../../../components/TextField/TextField";
import {
  EMAIL,
  STATUS_SUCCESS_200,
  STATUS_SUCCESS_201,
} from "../../../constants/constants";
import { useSessionStorage } from "../../../store/hooks/useSessionStorage";
import { UserRegistration } from "../types/types";
import { RegistrationHeader } from "components/Registration/Header/RegistrationHeader";
import SocialAuth from "components/SocialAuth/SocialAuth";
import { useUser } from "store/hooks/useUser";

const UserRegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const Registration = React.memo(() => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const { getCustomerId } = useUser();

  const [, setEmail] = useSessionStorage<string>(EMAIL);

  const handleUserRegistration = useCallback(
    async (values: UserRegistration) => {
      setEmail(values.email);
      const response = await registerUser(values);
      if (
        response.status === STATUS_SUCCESS_200 ||
        response.status === STATUS_SUCCESS_201
      ) {
        await generateOtp({ email: values.email });
        navigate("/verification/otp");
      } else {
        setErrorMessage(response.description);
      }
    },
    [navigate, setEmail]
  );

  return (
    <>
      <RegistrationHeader>
        <div className="sign_right_block">
          <div className="sign_right_head">
            <h1>Sign up</h1>
            <p>
              Please Sign Up to view your assessment results and customized
              portfolio recommendation
            </p>
          </div>
          <div className="card sign_right_card">
            <SocialAuth />
            <div className="sign_right_form">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  anonymousCustomerId: getCustomerId(),
                }}
                validationSchema={UserRegistrationSchema}
                onSubmit={(values: UserRegistration) => {
                  handleUserRegistration(values);
                }}>
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <div className="input_form">
                          <Field
                            type="text"
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            value={values.firstName}
                            onChange={handleChange}
                            component={TextField}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <div className="input_form">
                          <Field
                            type="text"
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            component={TextField}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input_form">
                      <Field
                        type="email"
                        id="emailAddress"
                        name="email"
                        label="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        component={TextField}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errorMessage && (
                      <p className="error text-capitalize">{errorMessage}</p>
                    )}
                    <div className="form_btn">
                      <button className="btn primary_btn" type="submit">
                        Continue
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <p className="already_acc">
                Already having an account with WealthZen?&nbsp;
                <a href="/login">Login</a>
              </p>
            </div>
          </div>
        </div>
      </RegistrationHeader>
    </>
  );
});
