import React, { useEffect, useRef, useState } from "react";
import { PortfolioConstituents } from "../../components/PortfolioConstituents";
import RiskSlider from "../../components/RiskSlider";
import { useUser } from "../../store/hooks/useUser";
import { useQuestions } from "../../store/hooks/useQuestion";
import { Options } from "@splidejs/react-splide";
import { postCustomisedPortFolio } from "../../apis/questionnaireResponseApi";
import { useRisk } from "../../store/hooks/useRisk";
import { useSessionStorage } from "../../store/hooks/useSessionStorage";
import {
  BALANCED,
  CUSTOMER_ID,
  DEFAULT_PERFORMANCE,
  ETF_KNOWLEDGE,
  FINANCIAL_KNOWLEDGE,
  OPTED_NO,
  OPTED_THEME1,
  OPTED_YES,
  PERFORMANCE,
  THEME,
  THEME_KNOWLEDGE,
} from "../../constants/constants";
import { Holdings, ModelPortfolioConstituents } from "../../typings/types";
import { SliderData } from "typings/HomePage/types/types";
import { LineData } from "typings/Charts/types/types";
import { PortfolioLineGraph } from "components/PortfolioLineGraph";
import { Widget } from "../../components/Widget/Widget";
import { ThemeWidget } from "../../components/Widget/ThemeWidget";
import { EtfWidget } from "../../components/Widget/EtfWidget";

type PortfolioSecProps = {
  portfolioData: any;
  objectiveFunctions: any;
  sliderData?: SliderData;
  lineData?: LineData[];
  tableData?: ModelPortfolioConstituents[];
};

export const PortfolioSec = () => {
  const { currentRisk, performance, theme } = useRisk();
  const [modal, setModal] = useState<boolean>(false);
  const [knowledge, setKnowledge] = useState<string>("");

  const myRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [performance, theme, currentRisk]);

  return (
    <>
      <div ref={myRef} className="portfolioSec_section">
        <RiskSlider key={currentRisk} />
      </div>

      {/* Modal Popup */}
      <Widget modal={modal} setModal={setModal}>
        {knowledge === THEME_KNOWLEDGE ? (
          <ThemeWidget knowledge={knowledge} setModal={setModal} />
        ) : (
          <EtfWidget knowledge={knowledge} setModal={setModal} />
        )}
      </Widget>
    </>
  );
};

export default React.memo(PortfolioSec);
