import React from "react";
import { Link } from "react-router-dom";

type AdminHeaderProps = {
  menu?: boolean;
  isLogout?: boolean;
};

export const AdminHeader = React.memo<AdminHeaderProps>(
  ({ menu, isLogout }) => {
    return (
      <nav className="navbar navbar-expand-lg quiz_head">
        <div className="container">
          <div className="back_btn d-flex justify-content-start ">
            {menu && (
              <>
                <Link to={"/admin/customersInfo"} className="m-2">
                  Users Table
                </Link>
                <Link to={"/admin/versionControl"} className="m-2">
                  Portfolio Versions
                </Link>
                <Link
                  className="m-2"
                  onClick={() =>
                    window.open("/admin/portfolio/upload", "_blank")
                  }
                  to={""}>
                  Upload Excel
                </Link>
              </>
            )}
          </div>
          <div className="header_logo">
            <a href="/dashboard">
              <img src="/images/logo.png" alt="logo" />
            </a>
          </div>
          <div className="already_usr">
            {isLogout && (
              <a
                href="/admin/login"
                onClick={() => sessionStorage.removeItem("isAdmin")}>
                <p className="float-right">
                  <b>Log Out</b>
                </p>
              </a>
            )}
          </div>
        </div>
      </nav>
    );
  }
);
