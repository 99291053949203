import axios from "axios";
import { TOKEN } from "./constants";

const URLS = {
  WZ_BACKEND:
    process.env.REACT_APP_WZ_BACKEND || "https://optimizer-api.wealthzen.com/",
  // process.env.REACT_APP_WZ_BACKEND || "http://18.143.158.249/",
};

export const GOOGLE_APP_ID =
  process.env.REACT_APP_GOOGLE_APP_ID ||
  "1060007469287-nemjho81q748f9gg6103nmmtm3lpr20j.apps.googleusercontent.com";

export const FACEBOOK_APP_ID =
  process.env.REACT_APP_FACEBOOK_APP_ID || "461141072155580";

export const WZ_BACKEND = () =>
  axios.create({
    baseURL: URLS.WZ_BACKEND,
  });

export const WZ_HOME_URL = "https://www.wealthzen.com/";
