import React, { useCallback, useState } from "react";
import { useAppContext } from "../../store/context";
import { useQuestions } from "../../store/hooks/useQuestion";
import { TextField } from "components/TextField/TextField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSessionStorage } from "store/hooks/useSessionStorage";

const initialDate = new Date();
initialDate.setDate(initialDate.getDate() - 2);

const UserLoginSchema = Yup.object().shape({
  date: Yup.date()
    .max(
      initialDate,
      "Date cannot be greater than " + initialDate.toISOString().split("T")[0]
    )
    .required("Required"),
  clientEmail: Yup.string()
    .email("Invalid email")
    .required("Required")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      "Invalid email format"
    ),
  clientName: Yup.string().required("Required"),
});

const StartPage = () => {
  const { setQuestion } = useQuestions();
  const [state] = useAppContext();
  const { start } = state;

  const startHandler = useCallback(async (values: any) => {
    sessionStorage.setItem("clientResponse", JSON.stringify(values));
    setQuestion(1001, "section1", true);
  }, []);

  return (
    <div className={`start_section ${start ? "dis-none" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="start_block">
              <div className="start_img">
                <img
                  src="./images/hero-img.png"
                  width={"70%"}
                  className="img-fluid mx-auto d-block"
                  alt="start"
                />
              </div>
              <div className="d-flex mx-auto start_content">
                <div className="col-md-8">
                  <h4>
                    Help us understand you better to start the perfect financial
                    journey by providing some information
                  </h4>
                </div>
                <div className="col-md-4">
                  <Formik
                    initialValues={{
                      date: initialDate,
                      clientEmail: "",
                      clientName: "",
                    }}
                    validationSchema={UserLoginSchema}
                    onSubmit={(values) => {
                      startHandler(values);
                    }}>
                    {({ values, isSubmitting, handleChange, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="input_form mb-3">
                          <label htmlFor="clientName">Client Name/Alias</label>
                          <Field
                            type="text"
                            id="clientName"
                            name="clientName"
                            onChange={handleChange}
                            value={values.clientName}
                            component={TextField}
                          />
                        </div>
                        <div className="input_form mb-3">
                          <label htmlFor="clientEmail">Client Email</label>
                          <Field
                            type="email"
                            id="clientEmail"
                            name="clientEmail"
                            onChange={handleChange}
                            value={values.clientEmail}
                            component={TextField}
                          />
                        </div>
                        <div className="input_form mb-3">
                          <label htmlFor="date">Investment Start Date</label>
                          <Field
                            type="date"
                            id="date"
                            name="date"
                            onChange={handleChange}
                            default={initialDate}
                            value={values.date ? values.date : ""}
                            component={TextField}
                          />
                        </div>
                        <div className="form_btn">
                          <button
                            className="btn primary_btn"
                            type="submit"
                            disabled={isSubmitting}>
                            Continue
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StartPage);
