import React, { useCallback, useState } from "react";
import {
  LoginUserBySocialType,
  RegisterUserBySocial,
} from "typings/SocialAuthentication/types";
import FacebookAuth from "./FacebookAuth";
import GoogleAuth from "./GoogleAuth";
import {
  getUserDetails,
  loginUserBySocial,
  registerUserBySocial,
} from "apis/userApi";
import {
  RESET,
  SECTION_1,
  SECTION_2,
  SECTION_3,
  STATUS_SUCCESS_200,
  TOKEN,
} from "constants/constants";
import { useNavigate } from "react-router-dom";
import { useUser } from "store/hooks/useUser";
import { useQuestions } from "store/hooks/useQuestion";
import { useRisk } from "store/hooks/useRisk";

const SocialAuth = React.memo(() => {
  const navigate = useNavigate();

  const { setCustomerId } = useUser();

  const { setQuestion } = useQuestions();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const { setInitialRisk } = useRisk();
  const getUserInformation = useCallback(async () => {
    const userInfo = await getUserDetails();

    if (userInfo.status === STATUS_SUCCESS_200) {
      if (userInfo.data.riskProfile) {
        setInitialRisk(userInfo.data.riskProfile);
      }
      if (userInfo.data.reset) {
        sessionStorage.setItem(RESET, JSON.stringify(userInfo.data.reset));
      }

      if (userInfo.data.customerPortfolioId) {
        navigate("/dashboard");
      } else if (
        !userInfo.data.reset &&
        userInfo.data.anonymousCustomerId &&
        !userInfo.data.customerPortfolioId
      ) {
        setCustomerId(userInfo.data.anonymousCustomerId);
        setQuestion(3002, SECTION_3);
        navigate("/profiling");
      } else if (
        userInfo.data.reset &&
        !userInfo.data.anonymousCustomerId &&
        !userInfo.data.customerPortfolioId
      ) {
        setQuestion(1001, SECTION_1);
        navigate("/profiling");
      } else if (userInfo.data.reset && userInfo.data.anonymousCustomerId) {
        setCustomerId(userInfo.data.anonymousCustomerId);
        if (userInfo.data.riskProfile) {
          setQuestion(3001, SECTION_3);
          navigate("/profiling");
        } else {
          setQuestion(2001, SECTION_2);
          navigate("/profiling");
        }
      }
    } else {
      setErrorMessage(userInfo.data.description);
    }
  }, [navigate, setCustomerId, setInitialRisk, setQuestion]);

  const registerUser = useCallback(
    async (userData: RegisterUserBySocial) => {
      const response = await registerUserBySocial(userData);
      if (response.status === STATUS_SUCCESS_200) {
        sessionStorage.setItem(TOKEN, response.data.accessToken);
        getUserInformation();
      } else {
        setErrorMessage(response.description);
      }
    },
    [getUserInformation]
  );

  const loginUser = useCallback(
    async (userData: LoginUserBySocialType) => {
      const response = await loginUserBySocial(userData);
      if (response.status === STATUS_SUCCESS_200) {
        sessionStorage.setItem(TOKEN, response.data.accessToken);
        getUserInformation();
      } else {
        setErrorMessage(response.description);
      }
    },
    [getUserInformation]
  );

  return (
    <div className="other_sign">
      <h4>Continue with</h4>
      <ul>
        <li>
          <GoogleAuth registerUser={registerUser} loginUser={loginUser} />
        </li>
        <li>
          <FacebookAuth registerUser={registerUser} loginUser={loginUser} />
        </li>
      </ul>
      {errorMessage && <p className="error text-capitalize">{errorMessage}</p>}
      <p className="horizontal_line">or</p>
    </div>
  );
});

export default SocialAuth;
