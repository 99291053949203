import { AppState } from "../../typings/types";
import { useAppContext } from "../context";

export const useAppAction = () => {
  const [state, setState] = useAppContext();

  const { questions, ...rest } = state;

  const toggleSideBar = () => {
    setState((old: AppState) => ({ ...old, sideBar: !old.sideBar }));
  };

  const setLoading = (mode: boolean) => {
    setState((old: AppState) => ({ ...old, loading: mode }));
  };

  return { appState: rest, toggleSideBar, setLoading };
};
