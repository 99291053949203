import React from "react";

type RegistrationHeaderProps = {
  children: JSX.Element;
};
export const RegistrationHeader = React.memo<RegistrationHeaderProps>(
  ({ children }) => {
    return (
      <section className="register_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="logo_block">
                <img src="/images/logo.png" alt="Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="container sign_section">
          <div className="row">
            <div className="col-lg-6 col-md-5 col-xs-12 d-sm-none d-md-block mob_dis_none">
              <div className="sign_left">
                <img src="/images/login_img.png" alt="Login" />
              </div>
            </div>
            <div className="col-lg-6 col-md-7 col-xs-12">{children}</div>
          </div>
        </div>
      </section>
    );
  }
);
