import { Routes, Route, Navigate } from "react-router-dom";
import Profiling from "../pages/Demographics/userJourney";
import { UserLogin } from "pages/UserLoginPage/UserLogin";
import { Registration } from "pages/RegistrationProcessPage/UserRegistration/Registration";
import { OTPVerification } from "pages/RegistrationProcessPage/OTPVerification/OTPVerification";

import RequireUserAuth from "components/RequireAuth/RequireUserAuth";
import { Home } from "pages/HomePage/Home";

const PublicRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/" />} /> */}

      <Route path="/" element={<Profiling />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/verification/otp" element={<OTPVerification />} />
      <Route path="/login" element={<UserLogin />} />
      <Route element={<RequireUserAuth />}>
        <Route path="/dashboard" element={<Home />} />
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
