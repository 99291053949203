import React, { useCallback, useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";

import Slide from "./Slide";
import { OPTIMIZED_DATA } from "constants/constants";

const RiskSlider = (props: any) => {
  const [currentObjectiveFun, setCurrentObjectiveFun] = useState<string>();
  const [portfolioData, setPortfolioData] = useState<any>();
  const [opt] = useState({
    type: "slide",
    padding: "10%",
    start: 0,
    perPage: 1,
    width: "95%",
    gap: "1.5rem",
    disable: true,
    drag: false,
  });

  useEffect(() => {
    const dataString = sessionStorage.getItem(OPTIMIZED_DATA);
    if (dataString) {
      const decodedData = JSON.parse(dataString);
      setPortfolioData(decodedData);
      setCurrentObjectiveFun(Object.keys(decodedData)[0]);
    }
  }, []);
  useEffect(() => {
    const elem: HTMLButtonElement | null = document.querySelector(
      "button.splide__arrow.splide__arrow--next"
    );
    // if (elem) {
    //   if (currentObjectiveFun === "volatility") {
    //     elem.disabled = true;
    //   } else {
    //     elem.disabled = false;
    //   }
    // }
  }, [currentObjectiveFun]);

  return (
    <>
      <div className="risk_slider_section">
        {portfolioData && (
          <Splide
            aria-label="My Favorite Images"
            options={opt}
            className="splide__slider__block">
            {Object.keys(portfolioData).map(
              (objectiveFunction: string, index: number) => {
                const data = portfolioData[objectiveFunction];
                return (
                  <SplideSlide key={index}>
                    <div className="slide_nav_section low_slide m-3">
                      {objectiveFunction === "annualised_return" && (
                        <h3 className="title_color text-decoration-underline">
                          Optimised to Maximize Annual Return
                        </h3>
                      )}
                      {objectiveFunction === "volatility" && (
                        <h3 className="title_color text-decoration-underline">
                          Optimised to Minimize Volatility
                        </h3>
                      )}
                      {objectiveFunction === "sharpe_ratio" && (
                        <h3 className="title_color text-decoration-underline">
                          Optimised for Sharpe Ratio
                        </h3>
                      )}
                    </div>

                    <Slide
                      objectiveFunction={objectiveFunction}
                      portfolioData={portfolioData[objectiveFunction]}
                    />
                  </SplideSlide>
                );
              }
            )}
          </Splide>
        )}
      </div>
    </>
  );
};
export default React.memo(RiskSlider);
