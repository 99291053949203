import { Table } from "components/Table/Table";
import { UpdatedDate } from "components/UpdatedDate";
import React from "react";
import {
  CustomerSecurityHolding,
  CustomerSecurityHoldingByDate,
} from "typings/HomePage/types/types";
import { holdingsTableHeaders } from "./HoldingsTableHeaders";

type HoldingProps = {
  holdingTable: CustomerSecurityHoldingByDate;
};

export const Holdings = React.memo<HoldingProps>(({ holdingTable }) => {
  const tableValue: CustomerSecurityHolding[] = Object.values(holdingTable)[0];
  const updateDate: string = tableValue[0].asOfDate;
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="holding_txt">
            <p>Here is the list of the securities in your account.</p>
          </div>
        </div>
        <div className="col-12">
          <UpdatedDate date={updateDate} />
        </div>
        <div className="col-12">
          <div className="table-responsive holding_table_res">
            <Table columns={holdingsTableHeaders} tableData={tableValue} />
          </div>
        </div>
      </div>
    </div>
  );
});
