import { UpdatedDate } from "components/UpdatedDate";
import { separator } from "pages/HomePage/Home";
import React from "react";
import {
  AggregatedPortfolioStats,
  AggregatedPortfolioStatsByDate,
} from "typings/HomePage/types/types";

type OverviewProps = {
  overViewValue: AggregatedPortfolioStatsByDate;
};

export const Overview = React.memo<OverviewProps>(({ overViewValue }) => {
  const overValue: AggregatedPortfolioStats[] = Object.values(overViewValue)[0];
  const overViewData: AggregatedPortfolioStats = overValue[0];
  const updateDate: string = overViewData.asOfDate;
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <UpdatedDate date={updateDate} />
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="dashboard_card">
            <div className="dashboard_card_img">
              <img src="/images/icons/home-img1.png" alt="home" />
            </div>
            <div className="dashboard_card_content">
              <p>Current value</p>
              <h4>
                <span>$</span> {separator(overViewData.currentValue)}
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="dashboard_card">
            <div className="dashboard_card_img">
              <img src="/images/icons/home-img2.png" alt="home" />
            </div>
            <div className="dashboard_card_content">
              <p>Invested value</p>
              <h4>
                <span>$</span> {separator(overViewData.investedValue)}
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="dashboard_card">
            <div className="dashboard_card_img">
              <img src="/images/icons/home-img3.png" alt="home" />
            </div>
            <div className="dashboard_card_content">
              <p>XIRR(Return)</p>
              <h4>
                {separator(overViewData.xirr.toFixed(2))} <span>%</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="dashboard_card1">
            <div className="dashboard_card1_content">
              {/* temporary  */}
              {/* <h4>TBD</h4>
              <p>TBD</p>
              <a href="/">Need link for external source</a> */}
              <h2>Coming soon!</h2>
            </div>
            <div>
              <div className="dashboard_card1_img">
                <img src="/images/icons/risk-profile1.png" alt="risk profile" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
