import { sendVersionNotification } from "apis/adminPortalApi";
import { Button } from "react-bootstrap";
import { portfolioRow } from "typings/AdminPortal/types";
import { TableColumns } from "typings/Table/types";

const detailsFormatter = (cell: string, row: portfolioRow) => {
  if (!row.versionNotificationTriggered) {
    return (
      <Button
        variant="primary"
        onClick={async () => {
          const res = await sendVersionNotification(row.portfolioID);
          if (res.data === "Update successful") {
            window.location.reload();
          } else {
            window.alert(res.data);
          }
        }}>
        Notify
      </Button>
    );
  } else {
    return <p>Notified</p>;
  }
};

const VersionControlTableHeaders: TableColumns[] = [
  {
    dataField: "portfolioName",
    text: "Portfolio Name",
  },
  {
    dataField: "portfolioID",
    text: "Portfolio ID",
  },

  {
    dataField: "newVersion",
    text: "New Version",
  },
  {
    dataField: "versionNotificationTriggered",
    text: "Action",
    formatter: detailsFormatter,
  },
];

export default VersionControlTableHeaders;
