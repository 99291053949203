import React from "react";
import { useQuestions } from "../store/hooks/useQuestion";
import { Meta, Option, Question, Section } from "../typings/types";

const QusProgress = ({ hide }: { hide: boolean }) => {
  const { questions, getQuestion } = useQuestions();
  const { meta, ...rest }: { meta: Meta; rest: Section } = questions;
  const currentQuestion = getQuestion();

  return (
    <div className={`progress_section ${hide ? "" : "dis-none"} `}>
      <div className="progress_block">
        <ul>
          {Object.values(rest).map((secBlock: Section, i: number) => {
            const allSectionDone = secBlock.filter(
              (element: Question) => !element.type && element.done
            );
            const alldone =
              allSectionDone.length ===
              secBlock.filter((element: Question) => !element.type).length;
            let liState = secBlock
              .map((each: Question) => each.id)
              .includes(meta.currentId)
              ? "active_main_dot"
              : "";

            const selectedQuestions = secBlock
              .map((each: Question) =>
                each.options.filter((element: Option) => element.selected)
              )
              .flat()
              .map((element: Option) => element.selected);

            liState =
              !secBlock
                .filter((element: Question) => !element.type)
                .map((each: Question) => each.id)
                .includes(meta.currentId) &&
              (selectedQuestions.length ===
                secBlock.filter((element: Question) => !element.type).length ||
                alldone)
                ? "active_main_completed"
                : liState;
            liState = currentQuestion?.end ? "active_main_completed" : liState;
            return (
              <li key={secBlock[0].id}>
                <span className={`qus_indicate ${liState}`}>
                  {liState === "active_main_dot" ? i + 1 : ""}
                </span>
                {secBlock.length > 1 &&
                  secBlock
                    .filter((element: Question) => !element.type)
                    .map((each: Question) => {
                      let state =
                        meta.currentId === each.id ? "active_dot" : "";
                      state =
                        meta.currentId !== each.id &&
                        (each.options.some(
                          (element: Option) => element.selected
                        ) ||
                          alldone)
                          ? "active_dot_fill"
                          : state;
                      return (
                        <span
                          className={`circle_dots ${state}`}
                          key={each.id}></span>
                      );
                    })}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(QusProgress);
