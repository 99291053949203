import React, { useEffect, useState } from "react";
import { PortfolioSec } from "../pages/Demographics/PortfolioInvestment";
import { OPTIMIZED_DATA } from "constants/constants";
import { removeNegativeValues } from "util/utils";

const ChartData = () => {
  return (
    <>
      <PortfolioSec

      // lineData={chartData?.portfolioStats}
      // sliderData={{
      //   pieData: chartData.holdings,
      // exp: chartData.expectedReturn,
      // best: chartData.bestOutcomeRange,
      // worst: chartData.worstOutcomeRange,
      // desc: chartData.portfolioDescription,
      // }}
      // tableData={chartData?.modelPortfolioConstituents}
      />
    </>
  );
};

export default React.memo(ChartData);
