import React, { useEffect } from "react";
import { useGoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { GOOGLE_APP_ID } from "../../constants/env-constants";
import { useLocation } from "react-router-dom";
import { useUser } from "../../store/hooks/useUser";
import { EMAIL, GOOGLE, LOGIN } from "../../constants/constants";
import { SocialAuthProps } from "typings/SocialAuthentication/types";

const GoogleAuth = React.memo<SocialAuthProps>(
  ({ registerUser, loginUser }) => {
    const { pathname } = useLocation();

    const path = pathname.split("/")[1];

    const { getCustomerId } = useUser();

    useEffect(() => {
      function start() {
        gapi.client.init({ clientId: GOOGLE_APP_ID, scope: "" });
      }
      gapi.load("client:auth2", start);
    });

    const onSuccess = (res: any) => {
      sessionStorage.setItem(EMAIL, res.profileObj.email);
      if (getCustomerId() && path !== LOGIN) {
        registerUser({
          email: res.profileObj.email,
          providerId: res.profileObj.googleId,
          imageUrl: res.profileObj.imageUrl,
          name: res.profileObj.name,
          provider: GOOGLE,
          anonymousCustomerId: getCustomerId(),
        });
      } else {
        loginUser({
          email: res.profileObj.email,
          providerId: res.profileObj.googleId,
          imageUrl: res.profileObj.imageUrl,
          name: res.profileObj.name,
          provider: GOOGLE,
        });
      }
    };

    const onFailure = (res: any) => {
      alert("Login Failed");
    };

    const { signIn } = useGoogleLogin({
      onSuccess,
      onFailure,
      clientId: GOOGLE_APP_ID,
      isSignedIn: false,
    });

    return (
      <button onClick={signIn}>
        <img src="./images/icons/google.png" alt="Google Icon" />
        <span>Google</span>
      </button>
    );
  }
);

export default GoogleAuth;
