import { SplideSlide } from "@splidejs/react-splide";
import {
  EQUITY_DM,
  EQUITY_EM,
  COMMODITIES,
  FIXED_INCOME_CORP,
  FIXED_INCOME_GOVT,
  CASH,
  EQUITY_THEME,
  FIXED_INCOME_THEME,
  CURRENT_ALLOCATION,
} from "constants/constants";
import { useEffect, useState } from "react";
import { TableColumns } from "typings/Table/types";
import { PieChart } from "./Charts/PieChart";
import { PortfolioConstituents } from "./PortfolioConstituents";

const Slide = (props: any) => {
  const constituentsOrder = [
    EQUITY_DM,
    EQUITY_EM,
    EQUITY_THEME,
    FIXED_INCOME_GOVT,
    FIXED_INCOME_CORP,
    FIXED_INCOME_THEME,
    COMMODITIES,
    CASH,
  ];
  const [currentAllocations, setCurrentAllocations] = useState<any>();
  const [showETF, setShowETF] = useState<boolean>(false);
  const [totalCurrentAllocation, setTotalCurrentAllocation] =
    useState<number>(0);
  const percentageFormatter = (cell: string, row: any) => {
    if (row.weight) {
      return <>{row.weight.toFixed(2)} %</>;
    } else {
      return <p>0 %</p>;
    }
  };
  const tableColumns: TableColumns[] = [
    {
      dataField: "factor.asset_class",
      text: "Asset Class",
    },
    {
      dataField: "etf",
      text: "ETF",
    },

    {
      dataField: "weight",
      text: "Suggested Allocation",
      formatter: percentageFormatter,
    },
    {
      dataField: "currentAllocation",
      text: "Current Allocation",
    },
    {
      dataField: "",
      text: "Delta",
    },
  ];

  useEffect(() => {
    const allocationStr = sessionStorage.getItem(CURRENT_ALLOCATION);
    if (allocationStr) {
      const storedAllocation = JSON.parse(allocationStr);
      setCurrentAllocations(storedAllocation);
    } else {
      setCurrentAllocations({
        [props.objectiveFunction]: props.portfolioData.assetClassBased.reduce(
          (acc: any, row: { "factor.asset_class": any }) => ({
            ...acc,
            [row?.["factor.asset_class"]]: {
              enteredValue: 0,
              currentAllocation: 0,
            },
          }),
          {}
        ),
      });
    }
  }, []);
  useEffect(() => {
    if (currentAllocations && currentAllocations[props.objectiveFunction]) {
      let total = 0;
      Object.values(currentAllocations[props.objectiveFunction]).forEach(
        (item: any) => {
          if (
            item.enteredValue !== "" &&
            typeof item.enteredValue === "number" &&
            !Number.isNaN(item.enteredValue)
          ) {
            total += parseFloat(item.enteredValue);
          }
        }
      );
      setTotalCurrentAllocation(total);
    }
  }, [currentAllocations, props.objectiveFunction]);

  return (
    props &&
    currentAllocations && (
      <SplideSlide>
        <div className="risk_slide_block">
          <div className="risk_slide_head mb-5">
            <h5>
              Personalised Asset Allocation based on selected risk profile,
              economic regime, and your objective of{" "}
              {props.objectiveFunction === "annualised_return" && (
                <span className="">‘maximizing returns’</span>
              )}
              {props.objectiveFunction === "volatility" && (
                <span className="">‘minimizing volatility’</span>
              )}
              {props.objectiveFunction === "sharpe_ratio" && (
                <span className="">‘optimizing Sharpe ratio’</span>
              )}
            </h5>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-xl-4">
              {props && <PieChart holdings={props.portfolioData.pieData} />}
            </div>
            <div className="col-sm-12 col-lg-12 col-xl-8">
              <table className="table ">
                <thead>
                  <tr className="title_color">
                    {tableColumns.map((col, index) => (
                      <th key={index} scope="col">
                        {col.text}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {constituentsOrder.map((constituent, index) => {
                    const matchingRow =
                      props.portfolioData.assetClassBased.find(
                        (row: any) => row["factor.asset_class"] === constituent
                      );
                    const objectiveFunctionData =
                      currentAllocations[props.objectiveFunction];
                    const assetClassData =
                      objectiveFunctionData &&
                      objectiveFunctionData[matchingRow["factor.asset_class"]];

                    const originalWeight = parseFloat(
                      matchingRow.weight.toFixed(2)
                    );
                    const enteredValue = parseFloat(
                      (assetClassData && assetClassData.enteredValue) || "0"
                    );
                    const delta = originalWeight - enteredValue;

                    return (
                      <tr key={index}>
                        <td>{matchingRow["factor.asset_class"]}</td>
                        <td>{matchingRow.etf}</td>
                        <td>{originalWeight} %</td>
                        <td>
                          <input
                            className="col-6"
                            type="number"
                            placeholder="Allocated"
                            value={enteredValue || ""}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              if (typeof value === "number") {
                                const str =
                                  sessionStorage.getItem(CURRENT_ALLOCATION);
                                if (str) {
                                  const currentAllocation = JSON.parse(str);
                                  const enteredValue = value;

                                  const updatedAllocations = {
                                    ...currentAllocation,
                                    [props.objectiveFunction]: {
                                      ...objectiveFunctionData,
                                      [matchingRow["factor.asset_class"]]: {
                                        enteredValue,
                                        delta: parseFloat(
                                          (
                                            originalWeight - enteredValue
                                          ).toFixed(2)
                                        ),
                                      },
                                    },
                                  };
                                  setCurrentAllocations(updatedAllocations);
                                  sessionStorage.setItem(
                                    CURRENT_ALLOCATION,
                                    JSON.stringify(updatedAllocations)
                                  );
                                } else {
                                  const enteredValue = value;
                                  const updatedAllocations = {
                                    ...currentAllocations,
                                    [props.objectiveFunction]: {
                                      ...objectiveFunctionData,
                                      [matchingRow["factor.asset_class"]]: {
                                        enteredValue,
                                        delta: parseFloat(
                                          (
                                            originalWeight - enteredValue
                                          ).toFixed(2)
                                        ),
                                      },
                                    },
                                  };
                                  setCurrentAllocations(updatedAllocations);
                                  sessionStorage.setItem(
                                    CURRENT_ALLOCATION,
                                    JSON.stringify(updatedAllocations)
                                  );
                                }
                              }
                            }}
                          />
                        </td>
                        <td
                          style={{
                            color:
                              delta > 0 ? "green" : delta < 0 ? "red" : "black",
                          }}>
                          {delta.toFixed(2)} %
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Total</td>

                    <td
                      style={{
                        color:
                          totalCurrentAllocation === 100
                            ? "green"
                            : totalCurrentAllocation === 0
                            ? "black"
                            : "red",
                      }}>
                      {totalCurrentAllocation.toFixed(2)} %
                    </td>

                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button
          className="btn primary_btn m-2"
          type="button"
          onClick={() => {
            setShowETF(!showETF);
          }}>
          {showETF ? "Hide" : "Show"} ETF Allocation
        </button>{" "}
        {showETF && (
          <PortfolioConstituents
            holdings={props.portfolioData.etfBased}></PortfolioConstituents>
        )}
      </SplideSlide>
    )
  );
};

export default Slide;
interface ETFRecord {
  etf: string;
  "factor.asset_class": string;
  "factor.Style"?: string;
  "factor.Sector"?: string;
  weight: number;
  objective_function: string;
}
